import React, { useCallback } from "react";
import {
  IFortnightAdm,
  IFortnightFormatData,
  IGetClientFortnightDue,
  IssueReissueRefundFortnight,
} from "../endpoints/DueAdvanceDetailsEndpoints";
import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { formatAmount } from "../../../../common/utils/common.utils";

const FortnightIssueReissueRefund = ({
  data,
}: {
  data: IGetClientFortnightDue;
}) => {
  const combinedData: IFortnightFormatData[] = data?.issue?.map((issueItem) => {
    const reissueItem = data?.reissue?.find(
      (reissue) => reissue.airticket_vendor_id === issueItem.airticket_vendor_id
    );
    const refundItem = data?.refunds?.find(
      (refund: IssueReissueRefundFortnight) =>
        refund.vrefund_vendor_id === issueItem.airticket_vendor_id
    );
    const admPurchase = data?.adm?.purchase_amount;

    const issueTotal = issueItem.total_purchase;
    const reissueTotal = reissueItem ? reissueItem.total_purchase : 0;
    const refundTotal = refundItem?.total_return || 0;
    // const admTotal = admItem?.purchase_amount || 0;

    const issueAdmTotal = Number(issueTotal) + Number(admPurchase);

    const combined =
      Number(issueAdmTotal) + Number(reissueTotal) + -Number(refundTotal);

    return {
      vendor_name: issueItem.vendor_name,
      issueAdmTotal,
      reissueTotal,
      refundTotal,
      combined,
    };
  });

  const Column: any = [
    {
      title: "SL.",
      render: (text: any, _data: any, index: number) => index + 1,
      // render: (_, data, index) =>
      //   ((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
      //   1 +
      //   index,
    },

    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
    },

    {
      title: "Issue/Adm",
      dataIndex: "issueAdmTotal",
      key: "issueAdmTotal",
      render: (curr: any) => <span>{formatAmount(curr)}</span>,
      align: "end",
    },
    {
      title: "Reissue",
      dataIndex: "reissueTotal",
      key: "reissueTotal",
      render: (curr: any) => <span>{formatAmount(curr)}</span>,
      align: "end",
    },
    {
      title: "Refund",
      dataIndex: "refundTotal",
      key: "refundTotal",
      render: (curr: any) => <span>{formatAmount(curr)}</span>,
      align: "end",
    },
    {
      title: "Combined",
      dataIndex: "combined",
      key: "combined",
      render: (curr: any) => <span>{formatAmount(curr)}</span>,
      align: "end",
    },
  ];

  return (
    <div>
      <Table
        size="small"
        bordered
        dataSource={combinedData?.length ? combinedData : []}
        columns={Column}
        pagination={false}
        // loading={{
        //   spinning: isLoading || isFetching,
        //   indicator: loadingIndicator,
        // }}
        // pagination={
        //   data?.count !== undefined && data?.count < 20
        //     ? false
        //     : {
        //         ...pagination,
        //         total: data?.count,
        //         showSizeChanger: true,
        //         pageSizeOptions: ["20", "100", "200", "500"],
        //         onChange: handlePaginationChange,
        //       }
        // }
        // summary={() => {
        //   const total = data?.data?.summary;

        //   return (
        //     <Table.Summary.Row>
        //       <Table.Summary.Cell index={0} colSpan={4}>
        //         <Typography.Text strong>Total:</Typography.Text>
        //       </Table.Summary.Cell>
        //       <Table.Summary.Cell index={1}>
        //         <div style={{ textAlign: "right", marginRight: 5 }}>
        //           <Typography.Text strong className="color-sales">
        //             {formatAmount(total?.total_sales)}
        //           </Typography.Text>
        //         </div>
        //       </Table.Summary.Cell>
        //       <Table.Summary.Cell index={2}>
        //         <div style={{ textAlign: "right", marginRight: 5 }}>
        //           <Typography.Text strong className="color-collection">
        //             {formatAmount(total?.total_payment)}
        //           </Typography.Text>
        //         </div>
        //       </Table.Summary.Cell>
        //       <Table.Summary.Cell index={3}>
        //         <div style={{ textAlign: "right", marginRight: 5 }}>
        //           <Typography.Text strong className="color-due">
        //             {formatAmount(total?.total_due)}
        //           </Typography.Text>
        //         </div>
        //       </Table.Summary.Cell>
        //       <Table.Summary.Cell index={3}></Table.Summary.Cell>
        //       <Table.Summary.Cell index={3}></Table.Summary.Cell>
        //     </Table.Summary.Row>
        //   );
        // }}
      />
    </div>
  );
};

export default FortnightIssueReissueRefund;

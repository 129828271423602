import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IViewInvoiceDetails } from "../../../common/types/commonInterfaces";
import { FormatDate, pagination } from "../../../common/utils/common.utils";
import FormHeaderTitle from "../../Invoice(Visa)/Components/FormHeaderTitle";
import NonCommissionCostInfo from "./NoncommissionCostInfo";
import AirTicketCostInfo from "../../IATA_AIR_TICKET/Components/AirTicketCostInfo";

type Props = {
  invoiceDetails: IViewInvoiceDetails | undefined;
};

const clientRefund: ColumnsType<any> = [
  {
    title: "SL.",
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: "Name",
    dataIndex: "client_name",
    key: "client_name",
  },
  {
    title: "Vouchar No.",
    dataIndex: "atrefund_vouchar_number",
    key: "atrefund_vouchar_number",
  },
  {
    title: "Return Amount",
    dataIndex: "crefund_return_amount",
    key: "crefund_return_amount",
  },
  {
    title: "Charge Amount",
    dataIndex: "crefund_charge_amount",
    key: "crefund_charge_amount",
  },
  {
    title: "Total Amount",
    dataIndex: "crefund_total_amount",
    key: "crefund_total_amount",
  },
  {
    title: "Profit",
    dataIndex: "crefund_profit",
    key: "crefund_profit",
  },
];
const vendor_refund: ColumnsType<any> = [
  {
    title: "SL.",
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: "Name",
    dataIndex: "vendor_name",
    key: "vendor_name",
  },
  {
    title: "Vouchar No.",
    dataIndex: "atrefund_vouchar_number",
    key: "atrefund_vouchar_number",
  },
  {
    title: "Return Amount",
    dataIndex: "vrefund_return_amount",
    key: "vrefund_return_amount",
  },
  {
    title: "Charge Amount",
    dataIndex: "vrefund_charge_amount",
    key: "vrefund_charge_amount",
  },
  {
    title: "Total Amount",
    dataIndex: "vrefund_total_amount",
    key: "vrefund_total_amount",
  },
];
const hajjiColumn: ColumnsType<any> = [
  {
    title: "SL.",
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: "Name",
    dataIndex: "hajiinfo_name",
    key: "hajiinfo_name",
  },
  {
    title: "Mobile",
    dataIndex: "hajiinfo_mobile",
    key: "hajiinfo_mobile",
  },

  {
    title: "Date of birth",

    key: "passport_date_of_birth",
    render: (_, data) => <> {FormatDate(data?.passport_date_of_birth)}</>,
  },
  {
    title: "Tracking",
    dataIndex: "hajiinfo_tracking_number",
    key: "hajiinfo_tracking_number",
  },
  {
    title: "Vouchar",
    dataIndex: "haji_info_vouchar_no",
    key: "haji_info_vouchar_no",
  },
  {
    title: "Serial",
    dataIndex: "hajiinfo_serial",
    key: "hajiinfo_serial",
  },
  {
    title: "Pre REg. Year",
    dataIndex: "haji_info_reg_year",
    key: "haji_info_reg_year",
  },
  {
    title: "Possible Hajj Year",
    dataIndex: "haji_info_possible_year",
    key: "haji_info_possible_year",
  },
];

const billingColumn: ColumnsType<any> = [
  {
    title: "SL.",
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: "Product",
    dataIndex: "product_name",
    key: "product_name",
  },
  {
    title: "Description",
    dataIndex: "billing_description",
    key: "billing_description",
  },
  {
    title: "Quantity",
    dataIndex: "billing_quantity",
    key: "billing_quantity",
  },
  {
    title: "Unit price",
    dataIndex: "billing_unit_price",
    key: "billing_unit_price",
  },
  {
    title: "Sub total",
    dataIndex: "billing_subtotal",
    key: "billing_subtotal",
  },
];

const ViewAirticketChildren = ({ invoiceDetails }: Props) => {
  const categoryId = invoiceDetails?.invoice_category_id;
  return (
    <div>
      {Number(categoryId) === 1 && (
        <AirTicketCostInfo tickets={invoiceDetails?.airticket_information} />
      )}
      {[2, 3].includes(Number(categoryId)) && (
        <NonCommissionCostInfo
          category_id={Number(categoryId)}
          tickets={invoiceDetails?.airticket_information}
          existing_ticket={invoiceDetails?.existing_ticket}
          discount={invoiceDetails?.invoice_discount}
        />
      )}

      {invoiceDetails?.refunds?.client_refund?.length && invoiceDetails ? (
        <>
          <FormHeaderTitle title="CLIENT REFUND INFORMATION" />
          <Table
            rowKey={(e) => e.airticket_ticket_no}
            dataSource={invoiceDetails?.refunds?.client_refund}
            columns={clientRefund}
            size="small"
            pagination={false}
          />
        </>
      ) : (
        ""
      )}
      {invoiceDetails?.refunds?.vendor_refund?.length && invoiceDetails ? (
        <>
          <FormHeaderTitle title="VENDOR REFUND INFORMATION" />
          <Table
            rowKey={(e) => e.airticket_ticket_no}
            dataSource={invoiceDetails?.refunds?.vendor_refund}
            columns={vendor_refund}
            size="small"
            pagination={false}
          />
        </>
      ) : (
        ""
      )}
      {invoiceDetails && invoiceDetails?.haji_information?.length && (
        <>
          <FormHeaderTitle title="HAJJI INFORMATION" />
          <Table
            rowKey={(e) => e.airticket_ticket_no}
            bordered
            className="invoiceBillingTable"
            rowClassName={"invoiceBillingTd"}
            dataSource={invoiceDetails?.haji_information}
            columns={hajjiColumn}
            size="small"
            pagination={pagination(invoiceDetails?.haji_information.length)}
          />
        </>
      )}

      {invoiceDetails?.billing_information && Number(categoryId) !== 2 && (
        <>
          <FormHeaderTitle title="BILLING INFO" />
          <Table
            size="small"
            className="invoiceBillingTable"
            rowClassName={"invoiceBillingTd"}
            dataSource={invoiceDetails?.billing_information}
            columns={billingColumn}
            bordered
            pagination={pagination(invoiceDetails?.billing_information?.length)}
            style={{ width: "8.27in" }}
          />
        </>
      )}
    </div>
  );
};

export default ViewAirticketChildren;

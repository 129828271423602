import { Col, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  IAirTicketInfo,
  IExistingTicket,
} from "../../../common/types/commonInterfaces";
import {
  Fixed2,
  formatAmount,
  formatNumber,
} from "../../../common/utils/common.utils";
import { TableType } from "../../../components/common/Invoice/InvoiceCostSubtotal";
import { findInvoiceRoute } from "../../../layout/components/SearchInvoices";
import FormHeaderTitle from "../../Invoice(Visa)/Components/FormHeaderTitle";

type Props = {
  tickets?: IAirTicketInfo[];
  category_id: number;
  discount: number | undefined;
  existing_ticket?: IExistingTicket[] | undefined;
};

const NonCommissionCostInfo = ({
  tickets,
  category_id,
  discount,
  existing_ticket,
}: Props) => {
  const costColumn: ColumnsType<IAirTicketInfo> = [
    {
      title: "Sl",
      align: "center",
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: "Ex. TKT",
      align: "center",
      dataIndex: "airticket_reissue_ticket_no",
      key: "airticket_reissue_ticket_no",
      hidden: category_id === 3 ? false : true,

      render: (curr, rec) => {
        if (curr) {
          return (
            <Link
              to={`../${
                findInvoiceRoute(rec?.invoice_category_id) +
                "/" +
                "details" +
                "/" +
                rec?.airticket_existing_invoiceid
              }`}
              state={`/airticket/details/${rec?.airticket_existing_invoiceid}`}
            >
              {curr}
            </Link>
          );
        }
      },
    },

    {
      title: "Reissue TKT",
      align: "center",
      dataIndex: "airticket_ticket_no",
      key: "airticket_ticket_no",
    },

    // {
    //   title: 'PNR',
    //   align: 'center',
    //   dataIndex: 'airticket_pnr',
    //   key: 'airticket_pnr',
    // },

    {
      title: "Vendor",
      dataIndex: "vendor_name",
      key: "vendor_name",
      align: "center",
    },

    // {
    //   title: 'Airline',
    //   dataIndex: 'airline_name',
    //   key: 'airline_name',
    //   align: 'center',
    // },

    // {
    //   title: 'Issue Date',
    //   render: (_, data) => (
    //     <>{dayjs(data?.airticket_issue_date).format('DD MMM YYYY')}</>
    //   ),
    //   key: 'airticket_issue_date',
    //   align: 'center',
    // },

    {
      title: "Penalties",
      dataIndex: "airticket_penalties",
      key: "airticket_penalties",
      align: "center",
      render: (curr) => <span>{formatAmount(curr)}</span>,
    },

    {
      title: "Fare Diff.",
      dataIndex: "airticket_fare_difference",
      key: "airticket_fare_difference",
      align: "center",
      render: (curr) => <span>{formatAmount(curr)}</span>,
    },

    {
      title: "Tax Diff.",
      dataIndex: "airticket_tax",
      key: "airticket_tax",
      align: "center",
      render: (curr) => <span>{formatAmount(curr)}</span>,
    },

    {
      title: "Commission",
      dataIndex: "airticket_commission_percent",
      key: "airticket_commission_percent",
      align: "center",
      render: (curr) => <span>{formatAmount(curr)}</span>,
    },

    {
      title: "AIT",
      dataIndex: "airticket_ait",
      key: "airticket_ait",
      align: "center",
      render: (curr) => <span>{formatAmount(curr)}</span>,
    },

    {
      title: "Extra Fee",
      dataIndex: "airticket_extra_fee",
      key: "airticket_extra_fee",
      align: "center",
      render: (curr) => <span>{formatAmount(curr)}</span>,
    },

    {
      title: "Client Price",
      dataIndex: "airticket_client_price",
      key: "airticket_client_price",
      align: "right",
      render: (curr) => (
        <span className="color-sales">{formatAmount(curr)}</span>
      ),
    },

    {
      title: "Purchase Price",
      dataIndex: "airticket_purchase_price",
      key: "airticket_purchase_price",
      align: "right",
      render: (curr) => (
        <span className="color-purchase">{formatAmount(curr)}</span>
      ),
    },

    {
      title: "Profit",
      dataIndex: "airticket_profit",
      key: "airticket_profit",
      align: "right",
      render: (curr) => (
        <span className={curr > 0 ? "color-profit" : "color-loss"}>
          {formatAmount(curr)}
        </span>
      ),
    },
  ];
  const existingTktColumn: ColumnsType<IExistingTicket> = [
    {
      title: "Sl",
      align: "center",
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: "Ex. Invoice",
      align: "center",
      dataIndex: "invoice_no",
      key: "invoice_no",
    },

    {
      title: "PNR",
      dataIndex: "airticket_pnr",
      key: "airticket_pnr",
    },

    {
      title: "Ticket No.",
      dataIndex: "airticket_ticket_no",
      key: "airticket_ticket_no",
      align: "center",
    },

    {
      title: "Journey Date",
      dataIndex: "airticket_journey_date",
      key: "airticket_journey_date",
      align: "center",
      render: (_, data) => (
        <>{dayjs(data?.airticket_journey_date).format("DD MMM YYYY")}</>
      ),
    },

    {
      title: "Client Price",
      dataIndex: "airticket_client_price",
      key: "airticket_client_price",
      align: "right",
      render: (curr) => (
        <span className="color-sales">{formatAmount(curr)}</span>
      ),
    },

    {
      title: "Purchase Price",
      dataIndex: "airticket_purchase_price",
      key: "airticket_purchase_price",
      align: "right",
      render: (curr) => (
        <span className="color-purchase">{formatAmount(curr)}</span>
      ),
    },
  ];

  const getSoldPrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Number(b.airticket_client_price || 0), 0)
      : 0;
  };
  const getPurchasePrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Number(b.airticket_purchase_price || 0), 0)
      : 0;
  };
  const getProfitPrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Number(b.airticket_profit || 0), 0)
      : 0;
  };

  const totalSold = getSoldPrice(tickets);
  const totalPurchese = getPurchasePrice(tickets);
  const totalProfit = getProfitPrice(tickets);
  const overAllProfit = totalProfit - Fixed2(discount);

  const billingInfoData: TableType[] = [
    {
      key: "1",
      name: "Total Profit",
      value: formatAmount(totalProfit),
    },
    {
      key: "2",
      name: "Discount",
      value: formatAmount(discount),
    },
    {
      key: "5",
      name: "Overall Profit",
      value: formatAmount(overAllProfit),
    },
  ];

  const billingInfoColumn: ColumnsType<any> = [
    {
      dataIndex: "name",
      key: "name",
      width: "150px",
    },
    {
      dataIndex: "value",
      key: "value",
      width: "120px",
      align: "right",
    },
  ];

  return (
    <div className="mt-20">
      {existing_ticket && existing_ticket[0] ? (
        <>
          <FormHeaderTitle title="EXISTING TICKET INFORMATION" />
          <Table
            rowKey={(e) => e.airticket_ticket_no}
            className="invoiceBillingTable"
            rowClassName={"invoiceBillingTd"}
            dataSource={existing_ticket}
            columns={existingTktColumn}
            bordered
            scroll={{ x: 900 }}
            pagination={false}
          />
        </>
      ) : (
        <></>
      )}

      <FormHeaderTitle title="COST INFORMATION" />
      <Table
        rowKey={(e) => e.airticket_ticket_no}
        className="invoiceBillingTable"
        rowClassName={"invoiceBillingTd"}
        dataSource={tickets}
        columns={costColumn}
        bordered
        scroll={{ x: 900 }}
        summary={(_) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  colSpan={Math.floor(costColumn.length) + category_id - 6}
                >
                  <Typography className="bold">Summary</Typography>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={2}>
                  <Typography className="align-right color-sales">{`${formatNumber(
                    totalSold
                  )}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography className="align-right color-purchase">{`${formatNumber(
                    totalPurchese
                  )}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <Typography className="align-right color-profit">{`${formatNumber(
                    totalProfit
                  )}`}</Typography>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
        pagination={false}
      />

      <Row justify={"end"} style={{ margin: 20 }}>
        <Col>
          <Table
            size="small"
            dataSource={billingInfoData.filter((item) => item?.value)}
            columns={billingInfoColumn}
            showHeader={false}
            pagination={false}
            style={{ width: "270px" }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default NonCommissionCostInfo;

import { Route } from "react-router";
import PermissionRoute from "../../auth/components/PermissionRoute";
import { TState } from "../../auth/states/userSlice";
import { rolePermissionType } from "../../common/types/commonTypes";
import AccountsReport from "../../modules/Reports/AccountsReport/pages/AccountsReport";
import ActivityLogs from "../../modules/Reports/Activity_Log/pages/ActivityLogs";
import AirTicketDetails from "../../modules/Reports/AirTicketReport/Pages/AirTicketDetails";
import AirTicketSummary from "../../modules/Reports/AirTicketReport/Pages/AirTicketSummary";
import AirlineWiseSalesReport from "../../modules/Reports/AirlineWiseSalesReport/pages/AirlineWiseSalesReport";
import AitReport from "../../modules/Reports/AitReport/pages/AitReport";
import BSPBill_Compare from "../../modules/Reports/BSPBillCheck/pages/BSPBill_Compare";
import BSPBill_List from "../../modules/Reports/BSPBillCheck/pages/BSPBill_List";
import ClientDiscount from "../../modules/Reports/ClientDiscount/pages/ClientDiscount";
import ClientWiseCollectionAndSales from "../../modules/Reports/ClientWiseCollectionAndSalesReport/pages/ClientWiseCollectionAndSalesReport";
import ClientWisePassengerList from "../../modules/Reports/ClientWisePassenger/pages/ClientWisePassengerList";
import CollectionReport from "../../modules/Reports/CollectionReport/page/CollectionReport";
import CountryWiseReport from "../../modules/Reports/CountryWiseReport/pages/CountryWiseReport";
import DailySalesReport from "../../modules/Reports/DailySalesReport/pages/DailySalesReport";
import DateWiseExpenseReport from "../../modules/Reports/DateWiseExpenseReport/pages/DateWiseExpenseReport";
import DueAdvanceDetails from "../../modules/Reports/DueAdvanceDetails/pages/DueAdvanceDetails";
import EmployeeExpenseReport from "../../modules/Reports/EmployeeExpenseReport/pages/EmployeeExpenseReport";
import GdsReport from "../../modules/Reports/GdsReport/pages/GdsReport";
import GroupWisePassengerList from "../../modules/Reports/GroupWisePassenger/pages/GroupWisePassengerList";
import HeadWiseExpenseReport from "../../modules/Reports/HeadWiseExpenseReport/pages/HeadWiseExpenseReport";
import InvoiceMoneyRecDiscount from "../../modules/Reports/InvoiceMoneyRecDiscount/pages/InvoiceMoneyRecDiscount";
import JourneyDateWiseClients from "../../modules/Reports/JourneyDateWiseClients/pages/JourneyDateWiseClients";
import LoanReport from "../../modules/Reports/Loan_Report/pages/LoanReport";
import Monthly_Sales_Earning from "../../modules/Reports/Monthly_Sales_Earning/pages/Monthly_Sales_Earning";
import OnlineTransactionChargeReport from "../../modules/Reports/OnlineTransactionChargeReport/pages/OnlineTransactionChargeReport";
import PassportStatusReport from "../../modules/Reports/PassportStatusReport/pages/PassportStatusReport";
import PassportWiseReport from "../../modules/Reports/PassportWiseReport/pages/PassportWiseReport";
import PayrollReport from "../../modules/Reports/PayrollReport/pages/payrollReports";
import PreRegistrationReport from "../../modules/Reports/PreRegistrationReport/pages/PreRegistrationReport";
import Over_All_Profit_Loss from "../../modules/Reports/Profit_Loss/pages/Over_All_Profit_Loss";
import Ticket_Wise_Profit_loss from "../../modules/Reports/Profit_Loss/pages/Ticket_Wise_Profit_loss";
import Visa_Wise_Profit_loss from "../../modules/Reports/Profit_Loss/pages/Visa_Wise_Profit_loss";
import RefundReport from "../../modules/Reports/RefundReport/pages/RefundReport";
import SalesManCommissionReport from "../../modules/Reports/SalesManCommissionReport/pages/SalesManCommissionReport";
import SalesManWiseClientDue from "../../modules/Reports/SalesManWiseClientDue/pages/SalesManWiseClientDue";
import SalesReportItemAndSalesMan from "../../modules/Reports/SalesReport(ItemAndSalesMan)/pages/SalesReportItemAndSalesMan";
import SummaryReport from "../../modules/Reports/SummaryReport/SummaryReport";
import TaxReportsToggle from "../../modules/Reports/TaxReport/pages/TaxReportsToggle";
import TotalCollectionPayments from "../../modules/Reports/TotalCollectionPayments/pages/TotalCollectionPayments";
import Total_Due_Advance_Agent from "../../modules/Reports/Total_Due_Advance_Agent/pages/Total_Due_Advance_Agent";
import Total_Due_Advance_Client from "../../modules/Reports/Total_Due_Advance_Client/pages/Total_Due_Advance_Client";
import Total_Due_Advance_Combined from "../../modules/Reports/Total_Due_Advance_Combined/pages/Total_Due_Advance_Combined";
import Total_Due_Advance_Employee from "../../modules/Reports/Total_Due_Advance_Empoloyee/pages/TotalDueAdvanceEmployee";
import Total_Due_Advance_Vendor from "../../modules/Reports/Total_Due_Advance_Vendor/pages/Total_Due_Advance_Vendor";
import UserLoginHistory from "../../modules/Reports/UserLoginHistory/pages/UserLoginHistory";
import VendorWisePurchaseAndPayment from "../../modules/Reports/VendorWisePurchaseAndPayment/pages/VendorWisePurchaseAndPayment";
import Void_and_Delete_List from "../../modules/Reports/Void_List/pages/Void_and_Delete_List";
import Agent_Reports from "../../modules/Reports/ledgers/pages/AgentLedger";
import ClientLedgers from "../../modules/Reports/ledgers/pages/ClientLedgers";
import CombinedLedgerReport from "../../modules/Reports/ledgers/pages/CombinedLedgerReport";
import VendorLedgers from "../../modules/Reports/ledgers/pages/VendorLedgers";
import OverAllRefoundReport from "../../modules/Reports/RefundReport/pages/OverAllRefoundReport";
import GiftReport from "../../modules/Reports/Gift/pages/GiftReport";

// DASHBOARD
export const ReportRoutes = (
  permission: rolePermissionType | undefined,
  user: TState
) => (
  <>
    <Route
      path="/reports/client_ledger"
      element={
        <PermissionRoute
          permission={permission?.report_ledgers}
          element={<ClientLedgers />}
        />
      }
    />
    <Route
      path="/reports/vendor_ledger"
      element={
        <PermissionRoute
          permission={permission?.report_ledgers}
          element={<VendorLedgers />}
        />
      }
    />
    <Route
      path="/reports/combined_ledger"
      element={
        <PermissionRoute
          permission={permission?.report_ledgers}
          element={<CombinedLedgerReport />}
        />
      }
    />
    <Route
      path="/reports/agent_reports"
      element={
        <PermissionRoute
          permission={permission?.report_ledgers}
          element={<Agent_Reports />}
        />
      }
    />

    <Route
      path="/reports/total_due_advance_vendor"
      element={
        <PermissionRoute
          permission={permission?.report_total_due_advance}
          element={<Total_Due_Advance_Vendor />}
        />
      }
    />

    <Route
      path="/reports/total_due_advance_client"
      element={
        <PermissionRoute
          permission={permission?.report_total_due_advance}
          element={<Total_Due_Advance_Client />}
        />
      }
    />

    <Route
      path="/total-due-advance-employee"
      element={
        <PermissionRoute
          permission={["*"]}
          element={<Total_Due_Advance_Employee />}
        />
      }
    />

    <Route
      path="/reports/total_due_advance_combinedclients"
      element={
        <PermissionRoute
          permission={permission?.report_total_due_advance}
          element={<Total_Due_Advance_Combined />}
        />
      }
    />
    <Route
      path="/reports/total_due_advance_agents"
      element={
        <PermissionRoute
          permission={permission?.report_total_due_advance}
          element={<Total_Due_Advance_Agent />}
        />
      }
    />

    <Route
      path="/reports/monthly_sales_and_earning"
      element={
        <PermissionRoute
          permission={permission?.sales_report}
          element={<Monthly_Sales_Earning />}
        />
      }
    />
    <Route
      path="/reports/airline_wise_sales_report"
      element={
        <PermissionRoute
          permission={permission?.sales_report}
          element={<AirlineWiseSalesReport />}
        />
      }
    />

    <Route
      path="/reports/sales_report_item_and_salesman"
      element={
        <PermissionRoute
          permission={permission?.sales_report}
          element={<SalesReportItemAndSalesMan />}
        />
      }
    />

    <Route
      path="/reports/client-wise-collection-and-sales-report"
      element={
        <PermissionRoute
          permission={permission?.sales_report}
          element={<ClientWiseCollectionAndSales />}
        />
      }
    />

    {/* ====================== profit loss ========================= */}
    <Route
      path="/reports/over_all_profit_loss"
      element={
        <PermissionRoute
          permission={permission?.profit_loss_report}
          element={<Over_All_Profit_Loss />}
        />
      }
    />

    <Route
      path="/reports/visa_wise_profit"
      element={
        <PermissionRoute
          permission={permission?.profit_loss_report}
          element={<Visa_Wise_Profit_loss />}
        />
      }
    />

    <Route
      path="/reports/ticket_wise"
      element={
        <PermissionRoute
          permission={permission?.profit_loss_report}
          element={<Ticket_Wise_Profit_loss />}
        />
      }
    />

    {/* ==================== expense report ========================== */}
    <Route
      path="/reports/air-ticket-summary"
      element={
        <PermissionRoute
          permission={permission?.expense_report}
          element={<AirTicketSummary />}
        />
      }
    />

    <Route
      path="/reports/air-ticket-details"
      element={
        <PermissionRoute
          permission={permission?.expense_report}
          element={<AirTicketDetails />}
        />
      }
    />
    <Route
      path="/reports/date_wise_expense_report"
      element={
        <PermissionRoute
          permission={permission?.expense_report}
          element={<DateWiseExpenseReport />}
        />
      }
    />

    <Route
      path="/reports/employee_expense_report"
      element={
        <PermissionRoute
          permission={permission?.expense_report}
          element={<EmployeeExpenseReport />}
        />
      }
    />

    <Route
      path="/reports/head_wise_expense_report"
      element={
        <PermissionRoute
          permission={permission?.expense_report}
          element={<HeadWiseExpenseReport />}
        />
      }
    />

    {/* ============== passport report ========================= */}
    <Route
      path="/reports/passport_status_report"
      element={
        <PermissionRoute
          permission={permission?.passport_report}
          element={<PassportStatusReport />}
        />
      }
    />

    <Route
      path="/reports/passport_wise_report"
      element={
        <PermissionRoute
          permission={permission?.passport_report}
          element={<PassportWiseReport />}
        />
      }
    />

    {/* /=================== pasenger list ========================== */}
    <Route
      path="/reports/client_wise_passenger_list"
      element={
        <PermissionRoute
          permission={permission?.passenger_list_report}
          element={<ClientWisePassengerList />}
        />
      }
    />

    <Route
      path="/reports/group_wise_passenger_list"
      element={
        <PermissionRoute
          permission={permission?.passenger_list_report}
          element={<GroupWisePassengerList />}
        />
      }
    />

    <Route
      path="/reports/vendor_wise_purchase_and_payment"
      element={
        <PermissionRoute
          permission={permission?.report_module}
          element={<VendorWisePurchaseAndPayment />}
        />
      }
    />

    <Route
      path="/reports/gift"
      element={
        <PermissionRoute
          permission={permission?.client_discount}
          element={<GiftReport />}
        />
      }
    />
    <Route
      path="/reports/client-discount"
      element={
        <PermissionRoute
          permission={permission?.client_discount}
          element={<ClientDiscount />}
        />
      }
    />
    <Route
      path="/reports/bsp-bill-compare"
      element={
        <PermissionRoute
          permission={permission?.client_discount}
          element={<BSPBill_Compare />}
        />
      }
    />
    <Route
      path="/reports/bsp-bill-compare/:id"
      element={
        <PermissionRoute
          permission={permission?.client_discount}
          element={<BSPBill_Compare />}
        />
      }
    />
    <Route
      path="/reports/bsp-bill-list"
      element={
        <PermissionRoute
          permission={permission?.client_discount}
          element={<BSPBill_List />}
        />
      }
    />

    <Route
      path="/reports/money-receipt-discount"
      element={
        <PermissionRoute
          permission={permission?.client_discount}
          element={<InvoiceMoneyRecDiscount />}
        />
      }
    />

    <Route
      path="/reports/tax_report"
      element={
        <PermissionRoute
          permission={permission?.report_module}
          element={<TaxReportsToggle />}
        />
      }
    />

    <Route
      path="/reports/payroll_reports"
      element={
        <PermissionRoute
          permission={permission?.payroll}
          element={<PayrollReport />}
        />
      }
    />

    <Route
      path="/reports/journey_date_wise_clients"
      element={
        <PermissionRoute
          permission={permission?.journey_date_wise_report}
          element={<JourneyDateWiseClients />}
        />
      }
    />

    <Route
      path="/reports/sales_man_collection_report"
      element={
        <PermissionRoute
          permission={permission?.sales_report}
          element={<SalesManCommissionReport />}
        />
      }
    />

    <Route
      path="/reports/sales-man-wise-client-due"
      element={
        <PermissionRoute
          permission={permission?.report_module}
          element={<SalesManWiseClientDue />}
        />
      }
    />
    <Route
      path="/reports/due-advance-details-and-summary"
      element={
        <PermissionRoute
          permission={permission?.report_module}
          element={<DueAdvanceDetails />}
        />
      }
    />

    <Route
      path="/reports/total_collection_payment_report"
      element={
        <PermissionRoute
          permission={permission?.report_module}
          element={<TotalCollectionPayments />}
        />
      }
    />
    <Route
      path="/reports/daily_sales_report"
      element={
        <PermissionRoute
          permission={permission?.sales_report}
          element={<DailySalesReport />}
        />
      }
    />
    <Route
      path="/reports/collection-report"
      element={
        <PermissionRoute
          permission={permission?.sales_report}
          element={<CollectionReport />}
        />
      }
    />

    <Route
      path="/reports/loan_report"
      element={
        <PermissionRoute
          permission={
            permission?.loan_report || user?.user_role === "SUPER_ADMIN"
          }
          element={<LoanReport />}
        />
      }
    />
    <Route
      path="/reports/ait_report"
      element={
        <PermissionRoute
          permission={permission?.ait_report}
          element={<AitReport />}
        />
      }
    />
    <Route path="/reports/GDS_Report" element={<GdsReport />} />

    <Route
      path="/reports/accounts_report"
      element={
        <PermissionRoute
          permission={permission?.accounts_report}
          element={<AccountsReport />}
        />
      }
    />
    <Route
      path="/reports/client_refund_report"
      element={
        <PermissionRoute
          permission={permission?.refund_report}
          element={<RefundReport />}
        />
      }
    />
    <Route
      path="/reports/refund_report"
      element={
        <PermissionRoute
          permission={permission?.refund_report}
          element={<OverAllRefoundReport />}
        />
      }
    />
    <Route
      path="/reports/transaction_charge_report"
      element={
        <PermissionRoute
          permission={permission?.refund_report}
          element={<OnlineTransactionChargeReport />}
        />
      }
    />

    <Route
      path="/reports/summary_report"
      element={
        <PermissionRoute
          permission={permission?.summary}
          element={<SummaryReport />}
        />
      }
    />
    <Route
      path="/reports/country_wise_report"
      element={
        <PermissionRoute
          permission={permission?.country_wise_report}
          element={<CountryWiseReport />}
        />
      }
    />
    <Route
      path="/reports/pre_registration_list"
      element={
        <PermissionRoute
          permission={
            permission?.preregistration_report ||
            user?.user_role === "SUPER_ADMIN"
          }
          element={<PreRegistrationReport />}
        />
      }
    />

    <Route
      path="/reports/user_login_history"
      element={
        <PermissionRoute
          permission={permission?.user_login_history}
          element={<UserLoginHistory />}
        />
      }
    />
    <Route
      path="/reports/activity_logs"
      element={
        <PermissionRoute
          permission={permission?.audit_trail}
          element={<ActivityLogs />}
        />
      }
    />

    <Route path="/reports/void" element={<Void_and_Delete_List />} />
  </>
);

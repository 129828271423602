import { api } from "../../../../app/baseQuery";
import { HTTPResponse } from "../../../../common/types/commonTypes";
import { DueAdvanceDetails } from "../../Api/constants";

export const DueAdvanceDetailsEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all SaleaManCommissionReport
    getDueAdvanceDetails: build.mutation<
      HTTPResponse<ITotalDueAdvanceDetailsAll>,
      {
        comb_client: string;
        data_type: "CLIENT" | "DETAILS" | "AIRLINE";
        airline_id: string;
        search: string;
        from_date?: string;
        to_date: string;
        current: number;
        pageSize: number;
      }
    >({
      query: ({
        airline_id,
        comb_client,
        data_type,
        from_date,
        search,
        to_date,
        current,
        pageSize,
      }) => ({
        url: `/report/due-advance-details?page=${current}&size=${pageSize}`,
        method: "POST",
        body: {
          airline_id,
          comb_client,
          data_type,
          from_date,
          search,
          to_date,
        },
      }),
      invalidatesTags: [{ type: "DueAdvanceDetails", id: DueAdvanceDetails }],
    }),

    getClientAdvance: build.query<
      HTTPResponse<IGetClientAdvance>,
      {
        current: number;
        pageSize: number;
        search?: string;
        data_type: "ADVANCE" | "DUE";
      }
    >({
      query: ({ current, pageSize, search, data_type }) => ({
        url: `/report/cl-due-advance?page=${current}&size=${pageSize}&search=${search}&data_type=${data_type}`,
        method: "GET",
      }),
      providesTags: [{ type: "DueAdvanceDetails", id: DueAdvanceDetails }],
    }),
    getClientFortnightDue: build.query<
      HTTPResponse<IGetClientFortnightDue>,
      {
        current?: number;
        pageSize?: number;
        week: string;
        billingType?: string;
        from_date?: string;
        to_date?: string;
        search: string;
      }
    >({
      query: ({
        current,
        pageSize,
        week,
        billingType,
        from_date,
        to_date,
        search,
      }) => ({
        url: `/report/fortnight-due?page=${current}&size=${pageSize}&search=${search}&week=${week}&billingType=${billingType}&from_date=${from_date}&to_date=${to_date}`,
        method: "GET",
      }),
      providesTags: [{ type: "DueAdvanceDetails", id: DueAdvanceDetails }],
    }),
    fortnightExcelData: build.query<
      any,
      {
        week: string;
        billingType: string;
        from_date: string;
        to_date: string;
        search: string;
      }
    >({
      query: ({ week, billingType, from_date, to_date, search }) => ({
        url: `/report/fortnight-due/excel?search=${search}&week=${week}&billingType=${billingType}&from_date=${from_date}&to_date=${to_date}`,
        responseHandler: async (response) => {
          const filename = `fortnight_client_due_advance.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
    }),
  }),
});

export const {
  useGetDueAdvanceDetailsMutation,
  useGetClientAdvanceQuery,
  useGetClientFortnightDueQuery,
  useLazyFortnightExcelDataQuery,
} = DueAdvanceDetailsEndpoints;

export interface ITotalDueAdvanceDetailsAll {
  results: ITotalDueAdvanceDetails[];
  total: ITotalDueAdvanceDetailsTotal;
}

export interface ITotalDueAdvanceDetails {
  invoice_org_agency: number;
  invoice_client_id?: number;
  invoice_combined_id?: number | null;
  client_name?: string;
  purchase: string;
  sales: string;
  pay: string;
  due: string;
  profit: string;
  airlines_code?: string | null;
  invoice_id?: number;
  invoice_no?: string;
  invoice_category_id: number;
  invoice_sales_date?: string;
  airline_id?: number | null;
  invoice_discount: string;
  overall_profit: string;
  service_charge: string;
  airline_name?: string | null;
}

export interface ITotalDueAdvanceDetailsTotal {
  purchase: string;
  sales: string;
  pay: string;
  due: string;
  invoice_discount: string;
  profit: string;
  last_balance: string;
}

export interface IGetClientAdvance {
  results: ClientAdvanceResults[];
  total_balance: number;
}
export interface ClientAdvanceResults {
  client_id: number;
  combine_id: number;
  client_category_id: number;
  client_entry_id: string;
  client_type: string;
  client_name: string;
  client_lbalance: string;
  client_created_date: string;
}

export interface IGetClientFortnightDueData {
  invoice_client_id: number;
  invoice_combined_id: number;
  client_name: string;
  client_mobile: string;
  client_source: string;
  contacted_person: string;
  total_sales: string;
  total_payment: string;
  total_due: string;
  ctrxn_lbalance: string;
}

export interface IssueReissueRefundFortnight {
  airticket_vendor_id: Number;
  vrefund_vendor_id?: Number;
  vendor_name: string;
  total_gross?: string;
  total_base?: string;
  total_purchase: string;
  total_return?: string | number;
}

export interface IFortnightAdm {
  vendor_id: number;
  gross_fare: string;
  tax: string;
  iata_commission: string;
  taxes_commission: string;
  ait: string;
  purchase_amount: string;
  overall_profit: string;
}

export interface IGetClientFortnightDue {
  billing_from_date: string;
  billing_to_date: string;
  sales_from_date: string;
  sales_to_date: string;
  data: IGetClientFortnightDueData[];
  summary: {
    total_sales: string;
    total_payment: string;
    total_due: string;
  };
  issue: IssueReissueRefundFortnight[];
  reissue: IssueReissueRefundFortnight[];
  refunds: IssueReissueRefundFortnight[];
  adm: IFortnightAdm;
}

export interface IFortnightFormatData {
  vendor_name: string;
  issueAdmTotal: string | number;
  reissueTotal: string | number;
  refundTotal: string | number;
  combined: string | number;
}

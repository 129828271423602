import { Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Fixed2, formatAmount } from "../../../../common/utils/common.utils";
import { IClientLedger } from "../types/clientLedgerTypes";
import { getLedgerRefLink } from "./ledgerUtils";
export const ClientLedgerUtils = (
  pagination: {
    current: number;
    pageSize: number;
  },
  isForPrint?: boolean
): ColumnsType<IClientLedger> => {
  const { Text } = Typography;

  return [
    {
      title: "SL.",
      align: "center",
      hidden: isForPrint,

      render: (_, rec, index) => {
        if (rec.type !== "BF")
          return (
            <>
              {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
                1 +
                index -
                1}
            </>
          );
      },
    },
    {
      title: "Trans Date",
      key: "ctrxn_created_at",
      width: 70,
      render: (_, record, index) => {
        if (record.type !== "BF") {
          return dayjs(record?.ctrxn_created_at).format("DD-MM-YYYY");
        }
      },
      align: "center",
    },

    {
      title: "Voucher",
      dataIndex: "ctrxn_voucher",
      hidden: isForPrint,

      render: (filed, rec) => {
        const link = getLedgerRefLink(rec.ctrxn_voucher, rec.ref_id);
        return link ? (
          <Link target="_blank" to={link}>
            {filed}
          </Link>
        ) : (
          <span>{filed}</span>
        );
      },
      key: "ctrxn_voucher",
    },

    {
      title: "Ticket",
      dataIndex: "ctrxn_airticket_no",
      key: "ctrxn_airticket_no",

      render: (_, data) => (
        <span>
          {data?.ctrxn_airticket_no
            ?.split(",")
            .slice(0, 3)
            .map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          {data?.ctrxn_airticket_no?.split(",").length > 3 && <span>...</span>}
        </span>
      ),
    },

    {
      title: "Routes",
      dataIndex: "ctrxn_route",
      key: "ctrxn_route",
      width: 100,
      render: (_, data) => (
        <span>
          {data.ctrxn_route?.split("\n").map((item, index) => (
            <li key={index}>{item.slice(0, 38)}</li>
          ))}
        </span>
      ),
    },

    {
      title: "PNR",
      dataIndex: "ctrxn_pnr",
      key: "ctrxn_pnr",

      render: (_, data) => (
        <span>
          {data?.ctrxn_pnr?.split(",").map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },

    {
      title: "Passenger",
      dataIndex: "ctrxn_pax",
      key: "ctrxn_pax",

      render: (curr, data) => {
        if (data.type !== "BF")
          if (curr) {
            return (
              <span>
                {data?.ctrxn_pax
                  ?.split(",")
                  .slice(0, 3)
                  .map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}

                {data?.ctrxn_pax?.split(",").length > 3 && <span>...</span>}
              </span>
            );
          } else {
            return "";
          }
      },
    },

    {
      title: "Particulars",
      dataIndex: "trxntype_name",
      key: "trxntype_name",

      render: (value, record, index) => {
        if (record.type === "BF") {
          return {
            children: (
              <Text strong style={{ textAlign: "center", fontSize: 14 }}>
                Balance B/F
              </Text>
            ),
            props: { colSpan: 1 },
          };
        } else {
          return value;
        }
      },
    },

    {
      title: "Pay Method",
      dataIndex: "ctrxn_pay_type",
      key: "ctrxn_pay_type",
      hidden: isForPrint,
    },
    {
      title: "Received By",
      dataIndex: "received_by",
      key: "received_by",
      hidden: isForPrint,
    },

    {
      title: "Debit",
      width: 100,

      render: (_, record) => {
        if (record.type !== "BF") {
          let amount = record.ctrxn_type == "DEBIT" ? record.ctrxn_amount : 0;

          return <p className="text-red"> {formatAmount(amount)} </p>;
        } else {
          return {
            children: (
              <Text strong style={{ fontSize: 15 }}>
                {Fixed2(record.bf) < 0
                  ? Math.abs(Fixed2(record.bf)).toLocaleString()
                  : 0}
              </Text>
            ),
            props: {
              colSpan: 1,
            },
          };
        }
      },
      key: "ctrxn_amount",
      align: "right",
    },

    {
      title: "Credit",
      render: (_, record) => {
        if (record.type !== "BF") {
          let amount = record.ctrxn_type == "CREDIT" ? record.ctrxn_amount : 0;

          return <p className="text-green"> {formatAmount(amount)} </p>;
        } else {
          return {
            children: (
              <Text strong style={{ fontSize: 15 }}>
                {Fixed2(record.bf) > 0
                  ? Math.abs(Fixed2(record.bf)).toLocaleString()
                  : 0}
              </Text>
            ),
            props: {
              colSpan: 1,
            },
          };
        }
      },
      align: "right",
    },
    {
      title: "Balance",
      width: 100,
      dataIndex: "ctrxn_lbalance",
      key: "ctrxn_lbalance",
      align: "right",

      render: (amount, rec) => {
        if (rec.type !== "BF")
          return isForPrint ? (
            <Typography.Text
              style={{
                color:
                  Fixed2(amount) === 0
                    ? "#18b4e9"
                    : Fixed2(amount) > 0
                    ? "green"
                    : "red",
              }}
            >
              {formatAmount(amount)}
            </Typography.Text>
          ) : (
            <Typography.Text
              style={{
                color:
                  Fixed2(amount) === 0
                    ? "#18b4e9"
                    : Fixed2(amount) > 0
                    ? "green"
                    : "red",
              }}
            >
              {formatAmount(Math.abs(amount))}
            </Typography.Text>
          );
      },
    },

    {
      title: "Note",
      dataIndex: "ctrxn_note",
      key: "ctrxn_note",
      hidden: isForPrint,
      width: 150,
      render: (column, data) => (
        <span>
          {data?.ctrxn_note?.split("\n")?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: "Created Date",
      hidden: isForPrint,
      key: "ctrxn_created_date",

      render: (_, record) => {
        if (record.bf !== "BF")
          return record?.ctrxn_created_date
            ? dayjs(record?.ctrxn_created_date).format("DD-MM-YYYY")
            : "";
      },
      align: "center",
    },

    {
      title: "Travel Date",
      hidden: !isForPrint,
      key: "ctrxn_journey_date",
      width: 70,
      render: (_, record) => {
        if (record.bf !== "BF")
          return record?.ctrxn_journey_date
            ? dayjs(record?.ctrxn_journey_date).format("DD-MM-YYYY")
            : "";
      },
      align: "left",
    },
    {
      title: "Return Date",
      hidden: !isForPrint,
      key: "ctrxn_return_date",
      width: 70,
      render: (_, record) => {
        if (record.bf !== "BF")
          return record?.ctrxn_return_date
            ? dayjs(record?.ctrxn_return_date).format("DD-MM-YYYY")
            : "";
      },
      align: "left",
    },
  ];
};

import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import {
  Fixed2,
  formatAmountAbs,
  formatNumber,
} from '../../../common/utils/common.utils';
import { IWeeklyMonthlyDailyMonthly } from '../interfaces/dashboard.interfaces';
import '../style/weeklyReport.css';
import DashboardTitle from './DashboardTitle';
import { getColor } from './MonthlyReport';

type Props = {
  data: IWeeklyMonthlyDailyMonthly | undefined;
  isDarkMode: any;
};

const DailyReport = ({ data, isDarkMode }: Props) => {
  const currentDate = dayjs().format('YYYY-MM-DD');

  const daily_summary = data?.dailySalesInfo;

  const profit_loss = Math.round(
    Fixed2(daily_summary?.sales) +
      Fixed2(daily_summary?.service_charge) -
      Fixed2(daily_summary?.discount) -
      Fixed2(daily_summary?.purchase)
  );

  const reportData = [
    {
      label: 'Sales',
      name: 'Sales Amount',
      value: formatNumber(daily_summary?.sales),
      url: `/reports/daily_sales_report?daily=${currentDate}`,
    },
    {
      label: 'Collection',
      name: 'Collection Amount',
      value: formatNumber(daily_summary?.collection),
      url: `/reports/collection-report?daily=${currentDate}`,
    },
    {
      label: 'Discount',
      name: 'Discount Amount',
      value: formatNumber(daily_summary?.discount),
      url: `/reports/head_wise_expense_report?daily=${currentDate}`,
    },
    {
      label: 'Purchased',
      name: 'Purchased Amount',
      value: formatNumber(daily_summary?.purchase),
      url: `/reports/vendor_wise_purchase_and_payment?daily=${currentDate}`,
    },

    {
      label: 'Payment',
      name: 'Payment Amount',
      value: formatNumber(daily_summary?.payment),
      url: `/vendors/payment?daily=${currentDate}`,
    },

    {
      label: 'Profit',
      name: profit_loss < 0 ? 'Loss' : 'Profit',
      value: formatAmountAbs(profit_loss),
      url: `/reports/over_all_profit_loss?daily=${currentDate}`,
    },
  ];

  const location = useLocation();

  return (
    <div className="dashboard-box-shape">
      <DashboardTitle
        title="DAILY SALES REPORT"
        link={`/reports/over_all_profit_loss?daily=${currentDate}`}
        linkTitle="View Overall"
      />
      <div style={{ padding: '0px 20px 10px' }}>
        {reportData?.map((item: any, index: number) => (
          <div
            className={`${isDarkMode ? 'report_box-dark' : ''} report_box${
              index === reportData?.length - 1 ? ' last_box' : ''
            }`}
            key={index}
          >
            <Link
              to={item.url}
              style={getColor(index)}
              state={location.pathname}
            >
              {item.name}
            </Link>
            <p>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyReport;

import { api } from "../../../../app/baseQuery";
import { HTTPResponse } from "../../../../common/types/commonTypes";
import { DueAdvanceDetails } from "../../Api/constants";
import { refund_type } from "./../../../Refund/RefundTypes/RefundTypes";

export const giftReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getGiftReport: build.query<
      HTTPResponse<IGiftResponse>,
      {
        current?: number;
        pageSize?: number;
        week: string;
        billingType?: string;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({
        current,
        pageSize,
        week,
        billingType,
        from_date,
        to_date,
      }) => ({
        url: `/configuration/account-contacts/reports?page=${current}&size=${pageSize}&week=${week}&billingType=${billingType}&from_date=${from_date}&to_date=${to_date}`,
        method: "GET",
      }),
      providesTags: ["gift-report"],
    }),
  }),
});

export const { useGetGiftReportQuery } = giftReportEndpoints;

export interface IGiftResponse {
  from_date: string;
  to_date: string;
  data: IGift[];
}

export interface IGift {
  ac_id: number;
  ac_account_id: number;
  ac_account_type: string;
  contact: null;
  ac_contact_name: string;
  ac_contact_designation: null;
  ac_contact_phone: null;
  ag_ac_id: number;
  gift_items: null | string;
  date: null | string;
  total_gift: number;
  // children?: IGift[];
}

import { useReactToPrint } from "react-to-print";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import React, { useRef, useState } from "react";
import {
  Button,
  Space,
  Table,
  TableColumnsType,
  Col,
  DatePicker,
  Input,
  Row,
  Segmented,
  Typography,
} from "antd";
import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { TableProps } from "antd/lib";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { SelectDataInput } from "../../../../components/common/FormItem/SelectCustomFeilds";
import { rangePresets } from "../../../../common/utils/common.utils";
import { IGift, useGetGiftReportQuery } from "../api/giftReportEndpoints";
import SendGiftModal from "../../../Configuration/contacts/modals/SendGiftModal";
import { setCommonModal } from "../../../../common/slices/modalSlice";
import { useAppDispatch } from "../../../../app/hooks";
import sendGift from "../../../../assets/give-gift.svg";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
// import { useGetClientFortnightDueQuery } from "../modules/Reports/DueAdvanceDetails/endpoints/DueAdvanceDetailsEndpoints";
interface DataType {
  key: React.ReactNode;
  name: string;
  age: number;
  address: string;
  children?: DataType[];
}

type TableRowSelection<T extends object = object> =
  TableProps<T>["rowSelection"];
const GiftReport = () => {
  const dispatch = useAppDispatch();
  const [isPrint, SetIsPrint] = useState(false);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Gift_Report`,
    pageStyle: `  @page {
      size: A4 landscape;
  
    }`,
  });
  const giftModal = (id: number) => {
    dispatch(
      setCommonModal({
        title: "Send gift",
        content: <SendGiftModal id={id} />,
        show: true,
        width: 500,
      })
    );
  };
  const columns: TableColumnsType<IGift> = [
    {
      title: "SL.",
      render: (_, data, index) => index + 1,
    },
    {
      title: "Company(Airline Name)",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Contact person",
      dataIndex: "ac_contact_name",
      key: "ac_contact_name",
    },
    {
      title: "Designation ",
      dataIndex: "ac_contact_designation",
      key: "ac_contact_designation",
    },
    {
      title: "Phone No",
      dataIndex: "ac_contact_phone",
      key: "ac_contact_phone",
    },
    {
      title: "Type",
      dataIndex: "ac_account_type",
      key: "ac_account_type",
    },
    {
      title: "Gift Items",
      dataIndex: "gift_items",
      key: "gift_items",
      render: (gift_items) => {
        const names = gift_items?.split(",");

        return names?.length > 0 ? (
          <ul>
            {names?.map((name: string, index: number) => (
              <li key={index}>{name}</li>
            ))}
          </ul>
        ) : (
          ""
        );
      },
    },
    {
      title: "Dates",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        const dates = date?.split(",");

        return dates?.length > 0 ? (
          <ul>
            {dates?.map((name: string, index: number) => (
              <li key={index}>{dayjs(name).format("DD-MM-YYYY")}</li>
            ))}
          </ul>
        ) : (
          ""
        );
      },
    },
    {
      title: "Total Gift",

      render: (record: IGift) => {
        return record?.total_gift > 0 ? (
          record?.total_gift
        ) : isPrint ? (
          ""
        ) : (
          <Button
            title="Send Gift"
            size="small"
            onClick={() => giftModal(record?.ag_ac_id)}
            type="primary"
            icon={<img src={sendGift} height={20} width={20} />}
          />
        );
      },
    },
  ];

  const [date, setDate] = useState<{
    from_date: string;
    to_date: string;
  }>({
    from_date: "",
    to_date: "",
  });
  const [week, setWeek] = useState("Default");
  const [billingType, setBillingType] = useState("previous");

  const [searchParams, setSearchParams] = useSearchParams();

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const { data, isLoading, isFetching } = useGetGiftReportQuery({
    ...pagination,
    week: week,
    billingType: billingType!,
    from_date: date.from_date,
    to_date: date.to_date,
  });

  console.log({ data: data?.data?.data });

  const setQueryParam = (key: string, value: any) => {
    const params = Object.fromEntries(searchParams.entries());
    if (value) {
      params[key] = value;
    } else {
      delete params[key];
    }
    setSearchParams(params);
  };

  const curr_month = dayjs().format("MMMM").toLocaleUpperCase();
  const curr_year = dayjs().format("YYYY");
  const lastDayOfMonth = dayjs().endOf("month").format("DD");

  const prev_month = dayjs().subtract(1, "months").format("MMMM");
  const prev_year = dayjs().subtract(1, "months").format("YYYY");

  const latsDayOfPreviousMonth = dayjs()
    .subtract(1, "month")
    .endOf("month")
    .format("DD");
  const previous = `1/${prev_month}/${prev_year} - 15/${prev_month}/${prev_year}`;
  const previous_next = `16/${prev_month}/${prev_year} - ${latsDayOfPreviousMonth}/${prev_month}/${prev_year}`;

  const week_1 = `01/${curr_month}/${curr_year} - 08/${curr_month}/${curr_year}`;
  const week_2 = `09/${curr_month}/${curr_year} - 15/${curr_month}/${curr_year}`;
  const week_3 = `16/${curr_month}/${curr_year} - 24/${curr_month}/${curr_year}`;
  const week_4 = `25/${curr_month}/${curr_year} - ${lastDayOfMonth}/${curr_month}/${curr_year}`;
  const dateRange = searchParams.get("date_range");

  const [fromDate, toDate] = dateRange?.split(",") || [];

  // const groupedData = (data?.data?.data || []).reduce((acc: any, item) => {
  //   const key = item.ac_account_id;

  //   if (!acc[key]) {
  //     acc[key] = {
  //       ...item,
  //       children: [],
  //     };
  //   } else {
  //     acc[key].children.push(item);
  //   }
  //   return acc;
  // }, {});

  // // Convert the grouped data back to an array
  // const processedData: any = Object.values(groupedData);

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const jsx_com = (
    <Table<IGift>
      columns={columns}
      rowClassName={(record) =>
        record?.total_gift ? "gift-sent" : "gift-not-sent"
      }
      dataSource={data?.data?.data || []}
      rowKey="ac_id"
      size="small"
      bordered
      loading={{
        spinning: isLoading || isFetching,
        indicator: loadingIndicator,
      }}
      pagination={
        data?.count !== undefined && data?.count > 50
          ? {
              ...pagination,
              total: data?.count,
              showSizeChanger: true,
              pageSizeOptions: ["50", "100", "200", "500"],
              onChange: handlePaginationChange,
            }
          : false
      }
    />
  );
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Gift Report" }}
      />
    </div>
  );
  return (
    <>
      {" "}
      <BreadCrumb arrOfOption={["Reports", "Gift"]} />
      <div style={{ marginBottom: "1rem", textAlign: "end" }}>
        <Button
          type="primary"
          onClick={() => {
            setTimeout(() => {
              handlePrint();
            }, 300);
            SetIsPrint(true);
          }}
        >
          <PrinterOutlined />
          Print
        </Button>
      </div>
      <div>
        <Row align={"middle"} gutter={[10, 10]}>
          <Col md={20} lg={20} xxl={14}>
            <Segmented<string>
              options={[
                {
                  value: "previous",
                  title: previous,
                  label: `${dayjs()
                    .subtract(1, "month")
                    .format("MMMM")
                    .toLocaleUpperCase()} (1-15)`,
                },
                {
                  value: "previous_next",
                  title: previous_next,
                  label: `${dayjs()
                    .subtract(1, "month")
                    .format("MMMM")
                    .toLocaleUpperCase()} (16-${latsDayOfPreviousMonth})`,
                },

                {
                  value: "first",
                  title: week_1,
                  label: `${curr_month} (01-08)`,
                },
                {
                  value: "second",
                  title: week_2,
                  label: `${curr_month} (09-15)`,
                },
                {
                  value: "third",
                  title: week_3,
                  label: `${curr_month} (16-23)`,
                },
                {
                  value: "fourth",
                  title: week_4,
                  label: `${curr_month} (24-${lastDayOfMonth})`,
                },
              ]}
              value={week}
              onChange={(value) => {
                setWeek(value);
                setQueryParam("week", value);
              }}
              style={{ width: "100%" }}
            />
          </Col>

          <SelectDataInput
            defaultValue={billingType}
            allowClear
            mdSize={4}
            size={4}
            placeholder="Billing Type"
            onChange={(value) => {
              setBillingType(String(value));
              setQueryParam("billingType", value);
            }}
            data={[
              { id: "previous", title: "Previous" },
              { id: "upcoming", title: "Upcoming" },
            ]}
          />

          <Col>
            <DatePicker.RangePicker
              value={[dayjs(fromDate), dayjs(toDate)]}
              presets={rangePresets}
              onChange={(e: any) => {
                if (e && e.length === 2) {
                  const fromDate = dayjs(e[0]).format("YYYY-MM-DD");
                  const toDate = dayjs(e[1]).format("YYYY-MM-DD");

                  setDate(() => ({
                    from_date: fromDate,
                    to_date: toDate,
                  }));
                } else {
                  setQueryParam("date_range", "");
                }
              }}
            />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <Row justify={"space-between"} align={"top"}>
          <Col>
            <Typography.Title
              level={5}
              style={{
                color: "#2a6375",
                fontFamily: "monospace",
                fontWeight: "bold",
              }}
            >
              FORTNIGHT PERIOD :{" "}
              {dayjs(data?.data?.from_date).format("DD-MMM-YYYY").toUpperCase()}{" "}
              to{" "}
              {dayjs(data?.data?.to_date).format("DD-MMM-YYYY").toUpperCase()}
            </Typography.Title>
          </Col>
        </Row>
        {jsx_com}

        <br />
        {print_content}
      </div>
    </>
  );
};

export default GiftReport;

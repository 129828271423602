import { Col, Form, FormInstance, Row, Select } from "antd";
import { useWatch } from "antd/es/form/Form";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import {
  FormInputItem,
  NumberInput,
} from "../../../../../components/common/FormItem/FormItems";
import {
  AutoComplete_input,
  SelectEmployee,
  SelectGender,
  SelectItem,
} from "../../../../../components/common/FormItem/SelectCustomFeilds";
import { useGetAllClientCategoryQuery } from "../../../../Configuration/clientCategory/endpoints/clientCategoryEndpoints";
import { useGetInvoiceNoQuery } from "../../../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints";
import { useGetSingleClientsQuery } from "../../api/endpoints/clientEndpoints";
import {
  categoryChildren,
  typeChildren,
} from "../../utils/utils_tsx/utilAddClient";
import Specific_airline_commission from "./Specific_airline_commission";

type props = { form: FormInstance<any> };

const Client_Add_Edit = ({ form }: props) => {
  const [clientType, setClientType] = useState<string>("");
  const [corporateType, setcorporateType] = useState<boolean>(false);

  const opening_balance_type: "Due" | "Advance" = useWatch(
    ["opening_balance_type"],
    form
  );

  const editMode = useRef(false);

  const { id } = useParams();

  if (id) {
    editMode.current = true;
  } else {
    editMode.current = false;
  }

  useEffect(() => {
    form.setFieldsValue({ client_category_id: 1 });
  }, []);

  useEffect(() => {
    setClientType(form?.getFieldValue("client_type"));
    if (editMode.current === false && form) {
      form.setFieldValue("client_type", "INDIVIDUAL");
    }
  }, [clientType]);
  const onCorporateType = (value: string) => {
    if (value == "CORPORATE") {
      setcorporateType(true);
    } else if (value == "INDIVIDUAL") {
      setcorporateType(false);
    }
  };

  let client;

  if (editMode.current) {
    client = useGetSingleClientsQuery(id as string);
  }

  const { data: clientCategories } = useGetAllClientCategoryQuery();

  // ITEMS INFO
  const items = [
    { label: "Due", value: "DEBIT" },
    { label: "Advance", value: "CREDIT" },
  ];

  if (!editMode.current) form.setFieldValue("client_walking_customer", 0);

  const { data: getInvoiceNo, isFetching } = useGetInvoiceNoQuery("CL", {
    skip: id ? true : false,
  });

  useEffect(() => {
    if (!id && getInvoiceNo?.data)
      form.setFieldsValue({
        client_entry_id: getInvoiceNo?.data?.replace("-", ""),
      });
  }, [isFetching, getInvoiceNo]);

  return (
    <>
      <div className="border p-5">
        <Row gutter={[10, 0]}>
          <Col span={6} xs={24} sm={12} md={12} lg={6}>
            <Form.Item name={["client_category_id"]} label="Client Category:">
              <Select
                showSearch
                placeholder="Select a Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              >
                {clientCategories?.data &&
                  categoryChildren(clientCategories?.data)}
              </Select>
            </Form.Item>
          </Col>
          <FormInputItem
            required
            size={6}
            name={"client_entry_id"}
            label={"Client Id"}
            readOnly
          />
          <Col span={6} xs={24} sm={12} md={12} lg={6}>
            <Form.Item
              name={"client_type"}
              label="Client Type"
              rules={[{ required: true, message: "Client Type is required" }]}
            >
              <Select
                showSearch
                placeholder="Select client type"
                optionFilterProp="typeChildren"
                onSelect={onCorporateType}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {typeChildren}
              </Select>
            </Form.Item>
          </Col>

          <FormInputItem
            required
            size={6}
            name={"client_name"}
            label={corporateType ? "Company name" : "Name"}
          />

          <FormInputItem
            size={6}
            name={"client_email"}
            label={corporateType ? "Company email" : "Email"}
            inputType="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          />

          {corporateType || (
            <SelectGender size={6} name="client_gender" label="Gender" />
          )}
          <FormInputItem
            size={6}
            name={"client_mobile"}
            label={corporateType ? "Company mobile" : "Mobile"}
          />
          <FormInputItem
            size={6}
            name={"client_address"}
            label={corporateType ? "Company address" : "Address"}
          />
          <SelectEmployee
            label={"Contacted person"}
            name="client_contacted_person"
            required
            size={6}
          />

          <SelectItem
            size={6}
            label="Walking Customer"
            name={"client_walking_customer"}
            items={[
              { label: "No", value: 0 },
              { label: "Yes", value: 1 },
            ]}
          />
          <AutoComplete_input
            size={6}
            label="Source"
            name={"client_source"}
            options={[
              "Existing Client",
              "Facebook Marketing",
              "Search Engine",
              "YouTube Marketing",
              "Direct Client",
              "Phone Call",
              "Linkedin",
              "Ads",
              "Agent",
              "Employee",
            ]}
          />

          <NumberInput
            size={6}
            name={"client_commission"}
            label={"Client commission"}
            placeholder={"Provide client commission"}
            maxChar={2}
          />
        </Row>
      </div>

      <div className="border p-5 mt-10">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <FormInputItem
            name={"client_designation"}
            label="Designation"
            smSize={12}
            mdSize={12}
            size={6}
          />

          <FormInputItem
            name={"client_trade_license"}
            label="Trade License No: "
            mdSize={12}
            size={6}
          />

          {/* Add Opening Balance */}
          <SelectItem
            disabled={editMode.current}
            name={"opening_balance_type"}
            label="Opening Balance type:"
            items={items}
            size={6}
          />

          {opening_balance_type && (
            <NumberInput
              required
              name={"opening_balance"}
              label="Opening Balance:"
              size={3}
              placeholder={"Opening Balance"}
            />
          )}

          {/* Add Credit Limit to client */}

          <NumberInput
            name={"client_credit_limit"}
            label="Credit Limit:"
            placeholder={"Credit Limit"}
            size={opening_balance_type ? 3 : 6}
          />
        </Row>
      </div>

      {editMode.current ? (
        <></>
      ) : (
        <div className="border p-5 mt-10">
          <Specific_airline_commission />
        </div>
      )}
    </>
  );
};

export default Client_Add_Edit;

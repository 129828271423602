import { Card, Col, Form, Row, Typography, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { Fixed2 } from "../../../../../common/utils/common.utils";
import {
  DateInput,
  FormButton,
  FormInput,
  NumberInput,
} from "../../../FormItem/FormItems";
import LoadingIndicator from "../../../spinner/LoadingIndicator";
import InvoiceVoidInputs from "./InvoiceVoidInputs";
import {
  IPostVoid,
  IVisaVendor,
  useLazyGetDetailsForVoidQuery,
  usePostVoidMutation,
  voidItemsVendor,
} from "./VoidApiEndpoints";
import TabVisaVoid from "./TabVisaVoid";

type Props = {
  invoice_category_id?: number;
  invoice_id: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TabInvoiceVoid = ({ invoice_id, setIsModalOpen }: Props) => {
  const [form] = Form.useForm();

  const [getDetails, { isFetching, isLoading, data }] =
    useLazyGetDetailsForVoidQuery();

  const [postVoid, { isError, isSuccess, isLoading: PostLoading, error }] =
    usePostVoidMutation();

  const backendError: any = error;

  const voidDetails = data?.data;

  useEffect(() => {
    if (invoice_id) getDetails(String(invoice_id));
  }, [invoice_id]);

  const onFinish = async (values: Submit) => {
    console.log(values);
    const body: IPostVoid = {
      invoice_void_date: values.void_date,
      client_charge: values.client_charge,
      net_total: values.net_total,
      invoice_no: values.invoice_no,
      cate_id: data?.data?.cate_id!,
      void_amount: values.void_amount,
      void_discount: values.void_discount,
      comb_client: voidDetails?.comb_client!,
    };

    if (voidDetails?.cate_id === 10) {
      body.invoice_vendors = values?.invoice_vendors?.map((item: any) => ({
        billing_id: item?.billing_id,
        billing_product_id: item?.billing_product_id,
        billing_quantity: item?.billing_quantity,
        billing_remaining_quantity: item?.billing_remaining_quantity,
        billing_unit_price: item?.billing_unit_price,
        billing_subtotal: item?.billing_subtotal,
        billing_cost_price: item?.billing_cost_price,
        billing_profit: item?.billing_profit,
        billing_vendor_id: `vendor-${item.billing_vendor_id}`,
      }));
    } else {
      body.invoice_vendors = values?.invoice_vendors?.map((item) => ({
        comb_vendor: item.comb_vendor,
        vendor_charge: item.vendor_charge,
        airticket_ticket_no: item.airticket_ticket_no,
        cost_price: Fixed2(item.cost_price),
        sales: Fixed2(item.sales),
        airticket_id: item.airticket_id,
      }));
    }

    await postVoid({ body: body, id: invoice_id });
  };

  const vendorInfo = useWatch("invoice_vendors", form);

  const formattedTicketInfo =
    voidDetails?.vendors?.length &&
    voidDetails?.vendors?.map((vendor: voidItemsVendor | IVisaVendor) => {
      if ("vendor_name" in vendor) {
        // Handle voidItemsVendor
        return {
          vendor_name: vendor.vendor_name,
          comb_vendor: vendor.comb_vendor,
          cost_price: vendor.cost_price,
          airticket_discount_total: vendor.airticket_discount_total,
          airticket_ticket_no: vendor.airticket_ticket_no,
          sales: vendor.sales,
          airticket_id: vendor.airticket_id,
        };
      } else {
        return {
          billing_id: vendor?.billing_id,
          billing_product_id: vendor?.billing_product_id,
          billing_quantity: vendor?.billing_quantity,
          billing_remaining_quantity: vendor?.billing_remaining_quantity,
          billing_unit_price: vendor?.billing_unit_price,
          billing_subtotal: vendor?.billing_subtotal,
          billing_cost_price: vendor?.billing_cost_price,
          billing_profit: vendor?.billing_profit,
          billing_vendor_id: `vendor-${vendor.billing_vendor_id}`,
        };
      }
    });

  //   CLIENT AND VENDOR TOTAL REFUND AMOUNT
  useEffect(() => {
    if (vendorInfo) {
      form.setFieldsValue({
        vendor_total_void: vendorInfo?.reduce(
          (a: any, b: any) => a + Fixed2(b?.vendor_charge),
          0
        ),
      });
      if (voidDetails?.cate_id !== 10) {
        form.setFieldsValue({
          net_total: vendorInfo?.reduce(
            (a: any, b: any) => a + Fixed2(b?.sales),
            0
          ),
        });
      } else {
        form.setFieldsValue({
          net_total: voidDetails?.net_total,
        });
      }
    }
  }, [vendorInfo]);

  useEffect(() => {
    if (voidDetails) {
      form.setFieldsValue({
        client_name: voidDetails?.client_name,
        invoice_no: voidDetails?.invoice_no,
        void_date: dayjs(),
      });

      form.setFieldValue("void_discount", Fixed2(voidDetails.invoice_discount));
      form.setFieldValue("invoice_vendors", formattedTicketInfo);
    }
  }, [voidDetails]);

  const client_price = useWatch("net_total", form);

  const client_discount = useWatch("void_discount", form);
  const client_charge = useWatch("client_charge", form);

  useEffect(() => {
    if (Number(client_charge) > Number(client_price)) {
      message.error("Client charge cannot be greater than client price");
      form.setFieldValue("client_charge", 0);
    }
  }, [client_price, client_charge]);

  const calculate_void_amount =
    Fixed2(client_price) + Fixed2(client_discount) - Fixed2(client_charge);

  useEffect(() => {
    form.setFieldsValue({ void_amount: calculate_void_amount || 0 });
  }, [calculate_void_amount]);

  useEffect(() => {
    if (isError) {
      message.error(backendError.data?.message || "Something went wrong!");
    } else if (isSuccess) {
      setIsModalOpen(false);
      message.success("The ticket has been voided.");
      form.resetFields();
    }
  }, [isError, isSuccess]);

  return (
    <>
      {(isLoading || isFetching) && <LoadingIndicator />}

      {voidDetails?.vendors?.length ? (
        <Form
          layout="vertical"
          labelAlign="left"
          onFinish={onFinish}
          form={form}
        >
          <Card size="small" style={{ width: "100%" }}>
            <Row gutter={20}>
              <FormInput
                label="Client"
                name={"client_name"}
                disabled
                size={8}
              />

              <FormInput
                label="Invoice No."
                name={"invoice_no"}
                readonly
                size={8}
              />

              <DateInput
                label="Void Date."
                name={"void_date"}
                size={8}
                required
              />

              {/* Nest Form.List */}
              <Col lg={24}>
                <Form.Item>
                  <Form.List name={["invoice_vendors"]}>
                    {(subFields, subOpt) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: 12,
                        }}
                      >
                        {subFields?.map((subField, index) =>
                          voidDetails?.cate_id === 10 ? (
                            <TabVisaVoid
                              key={index}
                              subField={subField}
                              subOpt={subOpt}
                              form={form}
                              index={index}
                            />
                          ) : (
                            <InvoiceVoidInputs
                              key={index}
                              subField={subField}
                              subOpt={subOpt}
                              form={form}
                            />
                          )
                        )}
                      </div>
                    )}
                  </Form.List>
                </Form.Item>
              </Col>

              <FormInput
                label="Client Price"
                name={"net_total"}
                readonly
                size={8}
              />
              <NumberInput
                label="Client Void Charge"
                name={"client_charge"}
                size={8}
              />
              <NumberInput
                label="Client Discount"
                name={"void_discount"}
                size={8}
              />

              {/* CLIENT REFUND INFORMATION */}

              <FormInput
                label="Void Amount"
                name={"void_amount"}
                readonly
                size={8}
              />
              {/* VENDOR REFUND INFORMATION */}
              <FormInput
                name={"vendor_total_void"}
                label="IATA Void Charge"
                size={8}
                readonly
              />

              <FormButton
                label="Submit"
                loading={isLoading || isFetching || PostLoading}
              />
            </Row>
          </Card>
        </Form>
      ) : (
        <>
          {(!isLoading || !isFetching) && (
            <Typography.Title
              level={4}
              style={{ textAlign: "center", color: "red" }}
            >
              No ticket found for void
            </Typography.Title>
          )}
        </>
      )}
    </>
  );
};

export default TabInvoiceVoid;

export const showModal = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(true);
};

export const handleOk = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(false);
};

export const handleCancel = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(false);
};

interface Submit {
  client_name: string;
  void_amount: number;
  void_discount: number;
  net_total: number;
  invoice_no: string;
  client_charge: number;
  void_date: string;
  invoice_vendors: InvoiceVendor[];
  vendor_total_void: number;
}

interface InvoiceVendor {
  vendor_name: string;
  comb_vendor: string;
  cost_price: number;
  airticket_ticket_no: string;
  vendor_charge: number;
  airticket_id: number;
  sales: string;
}

import {
  Col,
  DatePicker,
  Input,
  Row,
  Segmented,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  formatAmount,
  rangePresets,
} from "../../../../common/utils/common.utils";
import { SelectDataInput } from "../../../../components/common/FormItem/SelectCustomFeilds";
import { removeSpaceBeforeWord } from "../../../../components/common/Invoice/InvoiceListHeader";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import {
  IGetClientFortnightDue,
  IGetClientFortnightDueData,
  useGetClientFortnightDueQuery,
} from "../endpoints/DueAdvanceDetailsEndpoints";
import FortnightIssueReissueRefund from "./FortnightIssueReissueRefund";
type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
  isPrint: boolean;
  handlePaginationChange: (current: number, pageSize: number) => void;
};
const FortnightDueClientDueAdvance = ({
  pagination,
  handlePaginationChange,
  isPrint,
}: Props) => {
  const [date, setDate] = useState<{
    from_date: string;
    to_date: string;
  }>({
    from_date: "",
    to_date: "",
  });
  const [week, setWeek] = useState("Default");
  const [billingType, setBillingType] = useState("previous");
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, isFetching } = useGetClientFortnightDueQuery({
    search,
    week: week,
    billingType: billingType!,
    from_date: date.from_date,
    to_date: date.to_date,
  });

  const setQueryParam = (key: string, value: any) => {
    const params = Object.fromEntries(searchParams.entries());
    if (value) {
      params[key] = value;
    } else {
      delete params[key];
    }
    setSearchParams(params);
  };
  //   useEffect(() => {
  //     fetchExcel({
  //       week: week,
  //       billingType: billingType!,
  //       from_date: date.from_date,
  //       to_date: date.to_date,
  //     });
  //   }, [week, billingType, date.from_date, date.to_date]);
  const curr_month = dayjs().format("MMMM").toLocaleUpperCase();
  const curr_year = dayjs().format("YYYY");
  const lastDayOfMonth = dayjs().endOf("month").format("DD");

  const prev_month = dayjs().subtract(1, "months").format("MMMM");
  const prev_year = dayjs().subtract(1, "months").format("YYYY");

  const latsDayOfPreviousMonth = dayjs()
    .subtract(1, "month")
    .endOf("month")
    .format("DD");
  const previous = `1/${prev_month}/${prev_year} - 15/${prev_month}/${prev_year}`;
  const previous_next = `16/${prev_month}/${prev_year} - ${latsDayOfPreviousMonth}/${prev_month}/${prev_year}`;

  const week_1 = `01/${curr_month}/${curr_year} - 08/${curr_month}/${curr_year}`;
  const week_2 = `09/${curr_month}/${curr_year} - 15/${curr_month}/${curr_year}`;
  const week_3 = `16/${curr_month}/${curr_year} - 24/${curr_month}/${curr_year}`;
  const week_4 = `25/${curr_month}/${curr_year} - ${lastDayOfMonth}/${curr_month}/${curr_year}`;

  const Column = ({ pagination }: any) => {
    let arr: ColumnsType<IGetClientFortnightDueData> = [
      {
        title: "SL.",
        render: (_, data, index) =>
          ((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
          1 +
          index,
      },

      {
        title: "Client Name",
        dataIndex: "client_name",
        key: "client_name",

        render: (curr, rec) => {
          return (
            <Link
              to={
                rec.invoice_client_id
                  ? `/reports/client_ledger?client_query=${rec.invoice_client_id}`
                  : `/reports/combined_ledger?id=${rec?.invoice_combined_id}`
              }
            >
              {curr}
            </Link>
          );
        },
      },
      {
        title: "Contact Number",
        dataIndex: "client_mobile",
        key: "client_mobile",
      },
      {
        title: " Source",
        dataIndex: "client_source",
        key: "client_source",
      },
      {
        title: "Fortnight Sales ",
        dataIndex: "total_sales",
        key: "total_sales",
        render: (curr) => (
          <span className="color-sales">{formatAmount(curr)}</span>
        ),
        align: "end",
      },
      {
        title: "Fortnight Collection ",
        dataIndex: "total_payment",
        key: "total_payment",
        render: (curr) => (
          <span className="color-collection">{formatAmount(curr)}</span>
        ),
        align: "end",
      },
      {
        title: "Fortnight Due",
        dataIndex: "total_due",
        key: "total_due",
        render: (curr) => (
          <span className="color-due">{formatAmount(curr)}</span>
        ),
        align: "end",
      },
      {
        title: "Total Due",
        dataIndex: "ctrxn_lbalance",
        key: "ctrxn_lbalance",
        render: (curr) => (
          <Typography.Text
            style={{ color: Number(curr) < 0 ? "red" : "green" }}
          >
            {formatAmount(Math.abs(curr))}
          </Typography.Text>
        ),
        align: "end",
      },
      {
        title: "Contact By",
        dataIndex: "contacted_person",
        key: "contacted_person",
      },
    ];

    return arr;
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  // useEffect(() => {
  //   const fromDate = dayjs().format("YYYY-MM-DD");
  //   const toDate = dayjs().format("YYYY-MM-DD");
  //   setQueryParam("date_range", `${fromDate},${toDate}`);
  //   setDate(() => ({
  //     from_date: fromDate,
  //     to_date: toDate,
  //   }));
  // }, []);

  const dateRange = searchParams.get("date_range");

  const [fromDate, toDate] = dateRange?.split(",") || [];

  return (
    <div>
      {!isPrint && (
        <Row align={"middle"} gutter={[10, 10]}>
          <Col md={20} lg={20} xxl={14}>
            <Segmented<string>
              options={[
                {
                  value: "previous",
                  title: previous,
                  label: `${dayjs()
                    .subtract(1, "month")
                    .format("MMMM")
                    .toLocaleUpperCase()} (1-15)`,
                },
                {
                  value: "previous_next",
                  title: previous_next,
                  label: `${dayjs()
                    .subtract(1, "month")
                    .format("MMMM")
                    .toLocaleUpperCase()} (16-${latsDayOfPreviousMonth})`,
                },

                {
                  value: "first",
                  title: week_1,
                  label: `${curr_month} (01-08)`,
                },
                {
                  value: "second",
                  title: week_2,
                  label: `${curr_month} (09-15)`,
                },
                {
                  value: "third",
                  title: week_3,
                  label: `${curr_month} (16-23)`,
                },
                {
                  value: "fourth",
                  title: week_4,
                  label: `${curr_month} (24-${lastDayOfMonth})`,
                },
              ]}
              value={week}
              onChange={(value) => {
                setWeek(value);
                setQueryParam("week", value);
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={4} lg={4}>
            {" "}
            <Input
              placeholder="🔍 Search Here..."
              onBlur={(e) => {
                handleSearch(e);
                setQueryParam("search", e.target.value);
              }}
              onPressEnter={(e: any) => {
                handleSearch(e);
                setQueryParam("search", e.target.value);
              }}
              allowClear
            ></Input>
          </Col>
          <SelectDataInput
            defaultValue={billingType}
            allowClear
            mdSize={4}
            size={4}
            placeholder="Billing Type"
            onChange={(value) => {
              setBillingType(String(value));
              setQueryParam("billingType", value);
            }}
            data={[
              { id: "previous", title: "Previous" },
              { id: "upcoming", title: "Upcoming" },
            ]}
          />

          <Col>
            <DatePicker.RangePicker
              value={[dayjs(fromDate), dayjs(toDate)]}
              presets={rangePresets}
              onChange={(e: any) => {
                if (e && e.length === 2) {
                  const fromDate = dayjs(e[0]).format("YYYY-MM-DD");
                  const toDate = dayjs(e[1]).format("YYYY-MM-DD");
                  setQueryParam("date_range", `${fromDate},${toDate}`);
                  setDate(() => ({
                    from_date: fromDate,
                    to_date: toDate,
                  }));
                } else {
                  setQueryParam("date_range", "");
                }
              }}
            />
          </Col>
        </Row>
      )}

      <div style={{ marginTop: 10, textAlign: "end" }}>
        <Row justify={"space-between"} align={"top"}>
          {data?.data?.sales_from_date && data?.data?.sales_to_date ? (
            <Col>
              <Typography.Title
                level={5}
                style={{
                  color: "#2a6375",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                SALES PERIOD :{" "}
                {dayjs(data?.data?.sales_from_date)
                  .format("DD-MMM-YYYY")
                  .toUpperCase()}{" "}
                to{" "}
                {dayjs(data?.data?.sales_to_date)
                  .format("DD-MMM-YYYY")
                  .toUpperCase()}
              </Typography.Title>
            </Col>
          ) : (
            ""
          )}

          {data?.data?.billing_from_date && data?.data?.billing_to_date ? (
            <Col>
              <Typography.Title
                level={5}
                style={{
                  color: "#2a6375",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                BILLING PERIOD :{" "}
                {dayjs(data?.data?.billing_from_date)
                  .format("DD-MMM-YYYY")
                  .toUpperCase()}{" "}
                to{" "}
                {dayjs(data?.data?.billing_to_date)
                  .format("DD-MMM-YYYY")
                  .toUpperCase()}
              </Typography.Title>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Table
          size="small"
          bordered
          dataSource={data?.data?.data}
          columns={Column({
            pagination,
          })}
          loading={{
            spinning: isLoading || isFetching,
            indicator: loadingIndicator,
          }}
          pagination={
            data?.count !== undefined && data?.count < 20
              ? false
              : {
                  ...pagination,
                  total: data?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ["20", "100", "200", "500"],
                  onChange: handlePaginationChange,
                }
          }
          summary={() => {
            const total = data?.data?.summary;

            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}>
                  <Typography.Text strong>Total:</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <div style={{ textAlign: "right", marginRight: 5 }}>
                    <Typography.Text strong className="color-sales">
                      {formatAmount(total?.total_sales)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <div style={{ textAlign: "right", marginRight: 5 }}>
                    <Typography.Text strong className="color-collection">
                      {formatAmount(total?.total_payment)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <div style={{ textAlign: "right", marginRight: 5 }}>
                    <Typography.Text strong className="color-due">
                      {formatAmount(total?.total_due)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </div>

      <Row style={{ marginTop: "1rem" }} align={"middle"} justify={"center"}>
        <Col xs={24} md={16}>
          <FortnightIssueReissueRefund
            data={data?.data as IGetClientFortnightDue}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FortnightDueClientDueAdvance;

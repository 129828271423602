// import { Table, TableColumnsType, Typography } from 'antd';
// import { Link } from 'react-router-dom';
// import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
// import { invoiceViewStyle } from '../../../Quotation/Components/ViewGenerateInvoice';
// import {
//   IAirTicketRefundVendorDetails,
//   IAirtTicketRefundSingleDataType,
// } from '../../RefundTypes/RefundTypes';
// import { DateFormat } from '../../../../components/date_Format/date_format';
// import { Fixed2 } from '../../../../common/utils/common.utils';
// import { ColumnsType } from 'antd/es/table';
// import dayjs from 'dayjs';

// export type TableType = {
//   key: string;
//   name: any;
//   value?: any;
// };

// const AirTicketRefundDetails = ({
//   data,
// }: {
//   data?: IAirtTicketRefundSingleDataType;
// }) => {
//   const columns: TableColumnsType<IAirtTicketRefundSingleDataType> = [
//     {
//       title: (
//         <>
//           <p className="table-column-title">Refund Date</p>
//           <p className="table-column-title">Created Date</p>
//         </>
//       ),
//       render: (record) => (
//         <>
//           <p>{dayjs(record?.atrefund_date).format('YYYY-MM-DD')}</p>
//           <p>{dayjs(record.atrefund_create_date).format('YYYY-MM-DD')}</p>
//         </>
//       ),
//     },
//     {
//       title: (
//         <>
//           <p className="table-column-title">Voucher No</p>
//           <p className="table-column-title">Invoice No</p>
//         </>
//       ),
//       render: (record) => (
//         <>
//           <p>{record?.atrefund_vouchar_number}</p>
//           <p>{record?.invoice_no}</p>
//         </>
//       ),
//     },
//     {
//       title: 'Cleint Name',
//       dataIndex: 'client_name',
//       render: (_, { client_name, client_id }) => {
//         return (
//           <Link to={`/reports/client_ledger?client_query=${client_id}`}>
//             {client_name}
//           </Link>
//         );
//       },
//     },
//     { title: 'Total Sales', dataIndex: 'crefund_total_amount' },
//     { title: 'Refund Charge', dataIndex: 'crefund_charge_amount' },
//     { title: 'Total Return', dataIndex: 'crefund_return_amount' },
//   ];
//   const itemColumns: TableColumnsType<IAirTicketRefundVendorDetails> = [
//     {
//       title: (
//         <>
//           <p className="table-column-title">Ticket No</p>
//           <p className="table-column-title">Routes</p>
//         </>
//       ),
//       render: (record) => (
//         <>
//           <p>
//             {
//               <Link
//                 to={
//                   '../' +
//                   findInvoiceRoute(record.invoice_category_id) +
//                   '/' +
//                   'details' +
//                   '/' +
//                   record.invoice_id
//                 }
//                 state={location.pathname}
//               >
//                 {record.airticket_ticket_no}
//               </Link>
//             }
//           </p>
//           <p>{record.airticket_routes}</p>
//         </>
//       ),
//     },
//     // {
//     //   title: 'Ticket',
//     //   dataIndex: 'airticket_ticket_no',
//     //   render: (_, data) => (
//     //     <Link
//     //       to={
//     //         '../' +
//     //         findInvoiceRoute(data.invoice_category_id) +
//     //         '/' +
//     //         'details' +
//     //         '/' +
//     //         data.invoice_id
//     //       }
//     //       state={location.pathname}
//     //     >
//     //       {data.airticket_ticket_no}
//     //     </Link>
//     //   ),
//     // },
//     // { title: 'Routes', dataIndex: 'airticket_routes' },
//     { title: 'Pnr', dataIndex: 'airticket_pnr' },
//     { title: 'Vendor Name', dataIndex: 'vendor_name' },
//     { title: 'Purchase', dataIndex: 'vrefund_total_amount' },
//     { title: 'Penalties', dataIndex: 'vrefund_charge_amount' },
//     { title: 'Return Amount', dataIndex: 'vrefund_return_amount' },
//   ];
//   const billingInfoColumn: TableType[] = [
//     {
//       key: '1',
//       name: 'Profit',
//       value: data?.crefund_profit,
//     },
//   ];

//   console.log(data?.crefund_profit);
//   return (
//     <div style={invoiceViewStyle}>
//       <div>
//         <h2 style={{ color: '#18b4e9' }}>REFUND DETAILS</h2>

//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             justifyItems: 'center',
//             alignItems: 'center',
//             backgroundColor: '#fff',
//             padding: 20,
//             borderRadius: 5,
//           }}
//         >
//           <div>
//             <Typography.Title level={5} style={{ marginBottom: '5px' }}>
//               CLIENT INFORMATION
//             </Typography.Title>
//             <Table
//               columns={columns}
//               dataSource={data ? [data] : []}
//               pagination={false}
//             />
//             <Typography.Title
//               level={5}
//               style={{ marginBottom: '5px', marginTop: 20 }}
//             >
//               VENDOR INFORMATION
//             </Typography.Title>
//             <Table
//               columns={itemColumns}
//               dataSource={data ? data?.refund_info : []}
//               pagination={false}
//             />
//           </div>
//         </div>

//         <Table
//           size="small"
//           bordered
//           rowKey={'atrefund_id'}
//           className="invoiceBillingTable"
//           dataSource={data ? [data?.crefund_profit] : []}
//           columns={billingInfoColumn}
//           showHeader={false}
//           pagination={false}
//           style={{ width: '270px' }}
//         />
//       </div>
//     </div>
//   );
// };

// export default AirTicketRefundDetails;

import { Table, TableColumnsType, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { invoiceViewStyle } from '../../../Quotation/Components/ViewGenerateInvoice';
import {
  IAirTicketRefundVendorDetails,
  IAirtTicketRefundSingleDataType,
} from '../../RefundTypes/RefundTypes';
import dayjs from 'dayjs';

const AirTicketRefundDetails = ({
  data,
}: {
  data?: IAirtTicketRefundSingleDataType;
}) => {
  const columns: TableColumnsType<IAirtTicketRefundSingleDataType> = [
    {
      title: (
        <>
          <p className="table-column-title">Refund Date</p>
          <p className="table-column-title">Created Date</p>
        </>
      ),
      render: (record) => (
        <>
          <p>{dayjs(record?.atrefund_date).format('YYYY-MM-DD')}</p>
          <p>{dayjs(record.atrefund_create_date).format('YYYY-MM-DD')}</p>
        </>
      ),
    },
    {
      title: (
        <>
          <p className="table-column-title">Voucher No</p>
          <p className="table-column-title">Invoice No</p>
        </>
      ),
      render: (record) => (
        <>
          <p>{record?.atrefund_vouchar_number}</p>
          <p>{record?.invoice_no}</p>
        </>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      render: (_, { client_name, client_id }) => (
        <Link to={`/reports/client_ledger?client_query=${client_id}`}>
          {client_name}
        </Link>
      ),
    },
    { title: 'Total Sales', dataIndex: 'crefund_total_amount', align: 'right' },
    {
      title: 'Refund Charge',
      dataIndex: 'crefund_charge_amount',
      align: 'right',
    },
    {
      title: 'Total Return',
      dataIndex: 'crefund_return_amount',
      align: 'right',
    },
  ];

  const itemColumns: TableColumnsType<IAirTicketRefundVendorDetails> = [
    {
      title: (
        <>
          <p className="table-column-title">Ticket No</p>
          <p className="table-column-title">Routes</p>
        </>
      ),
      render: (record) => (
        <>
          <p>
            <Link
              to={
                '../' +
                findInvoiceRoute(record.invoice_category_id) +
                '/' +
                'details' +
                '/' +
                record.invoice_id
              }
              state={location.pathname}
            >
              {record.airticket_ticket_no}
            </Link>
          </p>
          <p>{record.airticket_routes}</p>
        </>
      ),
    },
    { title: 'Pnr', dataIndex: 'airticket_pnr' },
    { title: 'Vendor Name', dataIndex: 'vendor_name' },
    { title: 'Purchase', dataIndex: 'vrefund_total_amount', align: 'right' },
    { title: 'Penalties', dataIndex: 'vrefund_charge_amount', align: 'right' },
    {
      title: 'Return Amount',
      dataIndex: 'vrefund_return_amount',
      align: 'right',
    },
  ];

  return (
    <div style={invoiceViewStyle}>
      <div>
        <h2 style={{ color: '#18b4e9' }}>REFUND DETAILS</h2>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 5,
          }}
        >
          <div>
            <Typography.Title level={5} style={{ marginBottom: '5px' }}>
              CLIENT INFORMATION
            </Typography.Title>
            <Table
              columns={columns}
              dataSource={data ? [data] : []}
              pagination={false}
            />
            <Typography.Title
              level={5}
              style={{ marginBottom: '5px', marginTop: 20 }}
            >
              VENDOR INFORMATION
            </Typography.Title>
            <Table
              columns={itemColumns}
              dataSource={data ? data?.refund_info : []}
              pagination={false}
            />
          </div>
          <div
            style={{
              marginTop: 20,
              padding: '10px',
              backgroundColor: '#f9f9f9',
              border: '1px solid #ddd',
              borderRadius: '5px',
              textAlign: 'end',
            }}
          >
            <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
              Profit: {Number(data?.crefund_profit).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirTicketRefundDetails;

import { Tabs } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router";
import InvoiceNotFound from "../../../../auth/pages/InvoiceNotFound";
import { Fixed2 } from "../../../../common/utils/common.utils";
import InvoiceActivityLogs from "../../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs";
import CommonViewInvoice from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice";
import TabDetailsView from "../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView";
import TabPaymentView from "../../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView";
import ViewInvoiceHeader from "../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader";
import { FakeFlightAndPaxView } from "../../../../components/common/Invoice/ViewInvoiceInfo/edited_invoice/view/Billing/FakeFlightAndPaxView";
import ViewFakeInvoice from "../../../../components/common/Invoice/ViewInvoiceInfo/edited_invoice/view/ViewFakeInvoice";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import InoviceAirticketView from "../../../Experement/InoviceAirticketView";
import { Tab } from "../../../Invoice_Re_Issue/pages/ViewInvoiceInfo/ViewInvoiceReissue";
import AirTicketFlightAndPaxViewAirTicket from "../../Components/AirTicketFlightAndPaxViewAirTicket";
import ManualEditInvoiceAirTicket from "../../Components/ManualInvoiceAirTicketEdited/ManualEditInvoiceAirTicket";
import { useGetViewInvoiceQuery } from "../../api/endpoints/airticketInvoiceEndpoints";
import ListOfEmd from "../../../../components/common/Invoice/emd/ListOfEmd";

export type IProfitInfo = {
  profit: number | undefined;
  discount: number | undefined;
  service_charge: number | undefined;
  over_all_profit_loss: number;
  invoice_void_charge?: number;
};

const ViewInvoicesDetails = () => {
  const invoice_id = Fixed2(useParams().id);
  const [showFlightDetails, setShowFlightDetails] = useState<boolean>(true);
  const [showPaxDetails, setShowPaxDetails] = useState<boolean>(true);
  // GET INVOICE AIR TICKET DETAILS
  const {
    data: invoices,
    isLoading,
    isError,
  } = useGetViewInvoiceQuery(invoice_id);
  const invoiceData = invoices?.data;
  const profitInfo: IProfitInfo = {
    profit: invoiceData?.invoice_total_profit,
    discount: invoiceData?.invoice_discount,
    service_charge: invoiceData?.invoice_service_charge,
    over_all_profit_loss:
      Fixed2(invoiceData?.invoice_total_profit) -
      Fixed2(invoiceData?.invoice_discount) +
      Fixed2(invoiceData?.invoice_service_charge) +
      Number(invoiceData?.invoice_void_charge || 0),
    invoice_void_charge: invoiceData?.invoice_void_charge,
  };

  console.log(profitInfo);

  const [isClickPrint, setClickPrint] = useState(false);
  const viewInvoicePrintableRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = React.useRef<HTMLDivElement>(null);
  const viewEditedInvoiceRef = React.useRef<HTMLDivElement>(null);

  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = React.useState<
    "viewInvoice" | "costDetails" | "payments" | "edited" | "activity"
  >("viewInvoice");

  let selectedPrintableRef = viewInvoicePrintableRef;
  switch (selectedForPrint) {
    case "viewInvoice":
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case "costDetails":
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case "payments":
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case "edited":
      selectedPrintableRef = viewEditedInvoiceRef;
      break;
    case "activity":
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;

    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  if (isError) return <InvoiceNotFound />;

  const items: Tab[] = [
    {
      key: "1",
      label: "Invoice",
      children: (
        <>
          {invoiceData && (
            <>
              <CommonViewInvoice
                showFlightDetails={showFlightDetails}
                showPaxDetails={showPaxDetails}
                setShowPaxDetails={setShowPaxDetails}
                setShowFlightDetails={setShowFlightDetails}
                showPaxAndFlightButton
                invoiceData={invoiceData}
                viewInvoicePrintableRef={viewInvoicePrintableRef}
                heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
                children={
                  <AirTicketFlightAndPaxViewAirTicket
                    showFlightDetails={showFlightDetails}
                    showPaxDetails={showPaxDetails}
                    flights={invoiceData.flights}
                    paxDetails={invoiceData.pax_details}
                    airticketDetails={invoiceData.billing_info}
                    heightRefs={{
                      header_ref,
                      signiture_ref,
                      subtotal_ref,
                    }}
                    hideDiscount={invoiceData.invoice_show_discount}
                  />
                }
              />
            </>
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Details",
      children: (
        <TabDetailsView
          children={<InoviceAirticketView profitInfo={profitInfo} />}
          invoiceDetails={invoiceData}
          viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
        />
      ),
    },
    {
      key: "3",
      label: "Payments",
      children: (
        <>
          {invoiceData && (
            <TabPaymentView
              invoiceDetails={invoiceData}
              viewInvoicePaymentsRef={viewInvoicePaymentsRef}
            />
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "Edited Invoice",
      children: (
        <ManualEditInvoiceAirTicket
          viewEditedInvoiceRef={viewEditedInvoiceRef}
          invoiceData={invoiceData}
          isClickPrint={isClickPrint}
          selectedForPrint={selectedForPrint}
        />
      ),
    },

    //  viewEditedInvoiceRef={viewEditedInvoiceRef}
    //       invoiceData={invoiceData}
    //       isClickPrint={isClickPrint}
    //       selectedForPrint={selectedForPrint}
    // {
    //   key: "5",
    //   label: "Emd ",
    //   children: <ListOfEmd invoiceDetails={invoiceData} />,
    // },
    {
      key: "6",
      label: "Activity Log",
      children: (
        <InvoiceActivityLogs
          id={invoice_id}
          viewInvoiceAcivityRef={viewInvoiceAcivityRef}
        />
      ),
    },
  ];

  if (invoiceData?.is_edited) {
    items.push({
      key: "5",
      label: "Edited Invoice",
      children: (
        <>
          {invoiceData && (
            <ViewFakeInvoice
              invoiceData={invoiceData}
              viewInvoicePrintableRef={viewEditedInvoiceRef}
              heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
              children={
                <FakeFlightAndPaxView
                  flights={invoiceData.flights}
                  paxDetails={invoiceData.pax_details}
                  airticketDetails={invoiceData.airticket_information}
                  heightRefs={{
                    header_ref,
                    signiture_ref,
                    subtotal_ref,
                  }}
                />
              }
            />
          )}
        </>
      ),
    });
  }

  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={Number(invoiceData?.invoice_category_id)}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
        setClickPrint={setClickPrint}
        isClickPrint={isClickPrint}
      />
      {isLoading && <LoadingIndicator />}

      <Tabs
        style={{ marginTop: "15px" }}
        onChange={(e) => {
          switch (Fixed2(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint("viewInvoice");
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint("costDetails");
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint("payments");
              break;
            case 4:
              setSelectedForPrint && setSelectedForPrint("edited");
              break;
            case 5:
              setSelectedForPrint && setSelectedForPrint("activity");
              break;
            default:
              break;
          }
        }}
        type="card"
        items={items.sort((a, b) => Number(a.key) - Number(b.key))}
      ></Tabs>
    </>
  );
};

export default ViewInvoicesDetails;

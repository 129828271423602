import { ConfigProvider, theme } from "antd";
import React from "react";
import { IViewInvoiceDetails } from "../../../../common/types/commonInterfaces";
import ClientInfo from "../ClientInfo";

type Props = {
  invoiceDetails?: IViewInvoiceDetails;
  viewInvoiceCostDetailsRef: React.RefObject<HTMLDivElement>;
  children: JSX.Element;
};

const TabDetailsView = ({
  invoiceDetails,
  viewInvoiceCostDetailsRef,
  children,
}: Props) => {
  return (
    <div
      style={{
        backgroundColor: "rgba(211, 211, 211, 0.2)",
        padding: 20,
        minHeight: "70vh",
      }}
    >
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          ref={viewInvoiceCostDetailsRef}
          style={{
            maxWidth: 1200,
            margin: "0 auto",
            padding: 10,
            backgroundColor: "#fff",
          }}
        >
          <ClientInfo invoice_info={invoiceDetails as IViewInvoiceDetails} />

          {children}
        </div>
      </ConfigProvider>
    </div>
  );
};

export default TabDetailsView;

import {
  Col,
  Collapse,
  CollapseProps,
  DatePicker,
  Row,
  Segmented,
  theme,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import {
  Fixed2,
  formatAmount,
  rangePresets,
} from "../../../common/utils/common.utils";
import BreadCrumb from "../../../components/common/breadCrumb/BreadCrumb";
import { useBspSummaryViewQuery } from "../Api/Endpoints/DashboardEndpoints";
import BSP_Ticket_Issue from "../Components/BSP/BSP_Ticket_Issue";
import BSP_Ticket_Re_Issue from "../Components/BSP/BSP_Ticket_Re_Issue";
import BSP_Ticket_Refund from "../Components/BSP/BSP_Ticket_Refund";
import LoadingIndicator from "../../../components/common/spinner/LoadingIndicator";

const { Text } = Typography;
const ShowBspSummary = () => {
  const [date, setDate] = useState<{
    from_date: string;
    to_date: string;
  }>({
    from_date: "",
    to_date: "",
  });

  const searchParams = new URLSearchParams(location.search);
  const billing_type = searchParams.get("billing_type");

  const [week, setWeek] = useState("Default");

  const { data, isLoading, isFetching } = useBspSummaryViewQuery({
    week: week,
    billingType: billing_type!,
    from_date: date.from_date,
    to_date: date.to_date,
  });

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sales_start = searchParams.get("sales_start");
    const sales_end = searchParams.get("sales_end");

    if (sales_start && sales_end) {
      setDate({
        from_date: dayjs(sales_start).format("YYYY-MM-DD"),
        to_date: dayjs(sales_end).format("YYYY-MM-DD"),
      });
    }

    setWeek("Default");
  }, [location.search]);

  // const ticket_sales: ColumnsType<ticket_sales> = [
  //   {
  //     title: "SL",
  //     dataIndex: "airticket_tax",
  //     key: "airticket_tax",
  //     render: (_, data, index) => index + 1,
  //   },
  //   {
  //     title: "Sales Date",
  //     dataIndex: "invoice_sales_date",
  //     key: "invoice_sales_date",
  //     render: (curr) => (curr ? dayjs(curr).format("DD-MMM-YYYY") : "N/A"),
  //   },
  //   {
  //     title: "Invoice No.",
  //     dataIndex: "invoice_no",
  //     key: "invoice_no",
  //     render: (curr, data, index) => {
  //       return (
  //         <Link
  //           to={
  //             "../" +
  //             findInvoiceRoute(data.invoice_category_id) +
  //             "/" +
  //             "edit" +
  //             "/existing/" +
  //             data.invoice_id
  //           }
  //           state={`/${findInvoiceRoute(data.invoice_category_id)}`}
  //         >
  //           {curr}
  //         </Link>
  //       );
  //     },
  //   },

  //   {
  //     title: "Client Name",
  //     dataIndex: "client_name",
  //     key: "client_name",
  //     render: (rec, data) => {
  //       const { invoice_client_id, invoice_combined_id } = data;

  //       return (
  //         <Link
  //           to={
  //             invoice_client_id
  //               ? `/reports/client_ledger?client_query=${invoice_client_id}`
  //               : `/reports/combined_ledger?id=${invoice_combined_id}`
  //           }
  //         >
  //           {rec}
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     title: "Ticket No.",
  //     dataIndex: "ticket_no",
  //     key: "ticket_no",
  //     render: (curr) => {
  //       return (
  //         <Typography.Text>
  //           {curr?.split(",").map((item: string) => (
  //             <li style={{ fontSize: 11 }} key={item}>
  //               {item}
  //             </li>
  //           ))}
  //         </Typography.Text>
  //       );
  //     },
  //   },
  //   {
  //     title: "PNR",
  //     dataIndex: "pnr",
  //     key: "pnr",
  //     render: (curr) => {
  //       return (
  //         <Typography.Text>
  //           {curr?.split(",").map((item: string) => (
  //             <li style={{ fontSize: 11 }} key={item}>
  //               {item}
  //             </li>
  //           ))}
  //         </Typography.Text>
  //       );
  //     },
  //   },
  //   {
  //     title: "Invoice Type",
  //     dataIndex: "invoice_type",
  //     key: "invoice_type",
  //     render: (item) => {
  //       let color = "red";
  //       if (item === "IUR") {
  //         color = "cyan";
  //       } else if (item === "PNR") {
  //         color = "purple";
  //       }
  //       return <Tag color={color}>{item}</Tag>;
  //     },
  //   },
  //   {
  //     title: "Net Amount",
  //     dataIndex: "invoice_net_total",
  //     key: "invoice_net_total",
  //     render: (curr) => Fixed2(curr).toLocaleString(),
  //     align: "right",
  //   },
  //   {
  //     title: "Client Price",
  //     dataIndex: "total_client_price",
  //     key: "total_client_price",
  //     render: (curr) => Fixed2(curr).toLocaleString(),
  //     align: "right",
  //   },
  // ];

  // // TICKET SALES
  // let total_net_total = 0;
  // let client_price = 0;

  // useEffect(() => {
  //   // TICKET SALES
  //   total_net_total = 0;
  //   client_price = 0;
  // }, []);

  const curr_month = dayjs().format("MMMM").toLocaleUpperCase();
  const curr_year = dayjs().format("YYYY");
  const lastDayOfMonth = dayjs().endOf("month").format("DD");

  const prev_month = dayjs().subtract(1, "months").format("MMMM");
  const prev_year = dayjs().subtract(1, "months").format("YYYY");

  const latsDayOfPreviousMonth = dayjs()
    .subtract(1, "month")
    .endOf("month")
    .format("DD");

  const previous = `1/${prev_month}/${prev_year} - 15/${prev_month}/${prev_year}`;
  const previous_next = `16/${prev_month}/${prev_year} - ${latsDayOfPreviousMonth}/${prev_month}/${prev_year}`;

  const week_1 = `01/${curr_month}/${curr_year} - 08/${curr_month}/${curr_year}`;
  const week_2 = `09/${curr_month}/${curr_year} - 15/${curr_month}/${curr_year}`;
  const week_3 = `16/${curr_month}/${curr_year} - 24/${curr_month}/${curr_year}`;
  const week_4 = `25/${curr_month}/${curr_year} - ${lastDayOfMonth}/${curr_month}/${curr_year}`;

  const total_ticket_issue_purchase = data?.data?.ticket_issue?.reduce(
    (acc, curr) => Fixed2(curr.airticket_purchase_price) + acc,
    0
  );

  const total_ticket_re_issue__purchase = data?.data?.ticket_re_issue?.reduce(
    (acc, curr) => Fixed2(curr.airticket_purchase_price) + acc,
    0
  );

  const total_ticket_refund_return_amount = data?.data?.ticket_refund?.reduce(
    (acc, curr) => Fixed2(curr.vrefund_return_amount) + acc,
    0
  );

  const { token } = theme.useToken();
  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  // item label calculation for total start
  const ticketIssueTotal = useMemo(() => {
    return (
      data?.data?.ticket_issue?.reduce(
        (acc, curr) => Fixed2(curr.airticket_purchase_price) + acc,
        0
      ) || 0
    );
  }, [data?.data?.ticket_issue]);

  const ticketReIssueTotal = useMemo(() => {
    return (
      data?.data?.ticket_re_issue?.reduce(
        (acc, curr) => Fixed2(curr.airticket_purchase_price) + acc,
        0
      ) || 0
    );
  }, [data?.data?.ticket_re_issue]);

  const ticketRefundTotal = useMemo(() => {
    return (
      data?.data?.ticket_refund?.reduce(
        (acc, curr) => Fixed2(curr.vrefund_return_amount) + acc,
        0
      ) || 0
    );
  }, [data?.data?.ticket_refund]);
  //item label  calculation for total end
  const getItems: (panelStyle: CSSProperties) => CollapseProps["items"] = (
    panelStyle
  ) => [
    {
      key: "1",
      label: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>TICKET ISSUE</Typography>
          <Typography>{formatAmount(ticketIssueTotal)}</Typography>
        </div>
      ),
      children: (
        <BSP_Ticket_Issue
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>TICKET RE-ISSUE</Typography>
          <Typography>{formatAmount(ticketReIssueTotal)}</Typography>
        </div>
      ),
      children: (
        <BSP_Ticket_Re_Issue
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>TICKET REFUND</Typography>
          <Typography>{formatAmount(ticketRefundTotal)}</Typography>
        </div>
      ),
      children: (
        <BSP_Ticket_Refund
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      ),
      style: panelStyle,
    },
  ];
  const [activeKey, setActiveKey] = useState<string | string[]>("");

  return (
    <>
      <BreadCrumb arrOfOption={["BSP Summary"]} />

      {(isLoading || isFetching) && <LoadingIndicator />}
      <div style={{ display: "flex", flexDirection: "column", rowGap: "30px" }}>
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <Segmented<string>
              options={[
                {
                  value: "Default",
                  title: `Default Options`,
                  label: `Default (${billing_type})`,
                },
                {
                  value: "previous",
                  title: previous,
                  label: `${dayjs()
                    .subtract(1, "month")
                    .format("MMMM")
                    .toLocaleUpperCase()} (1-15)`,
                },
                {
                  value: "previous_next",
                  title: previous_next,
                  label: `${dayjs()
                    .subtract(1, "month")
                    .format("MMMM")
                    .toLocaleUpperCase()} (16-${latsDayOfPreviousMonth})`,
                },

                {
                  value: "first",
                  title: week_1,
                  label: `${curr_month} (01-08)`,
                },
                {
                  value: "second",
                  title: week_2,
                  label: `${curr_month} (09-15)`,
                },
                {
                  value: "third",
                  title: week_3,
                  label: `${curr_month} (16-23)`,
                },
                {
                  value: "fourth",
                  title: week_4,
                  label: `${curr_month} (24-${lastDayOfMonth})`,
                },
              ]}
              value={week}
              onChange={(value) => {
                setWeek(value);
              }}
            />
          </Col>
          <Col>
            <DatePicker.RangePicker
              value={[dayjs(date.from_date), dayjs(date.to_date)]}
              presets={rangePresets}
              onChange={(e) =>
                setDate(() => ({
                  from_date: e?.length ? dayjs(e[0]).format("YYYY-MM-DD") : "",
                  to_date: e?.length ? dayjs(e[1]).format("YYYY-MM-DD") : "",
                }))
              }
            />
          </Col>
        </Row>
        <Collapse
          size="middle"
          bordered={false}
          items={getItems(panelStyle)}
          style={{ background: token.colorBgContainer }}
          activeKey={activeKey}
          accordion
          onChange={(e) => setActiveKey(e)}
        />

        <Typography.Title
          level={4}
          style={{
            textAlign: "end",
            color: "#14a2d2",
            fontWeight: "bold",
          }}
        >
          COMBINED TOTAL : {"  "}
          <span>
            {(
              (total_ticket_issue_purchase ?? 0) +
              (total_ticket_re_issue__purchase ?? 0) -
              (total_ticket_refund_return_amount ?? 0)
            ).toLocaleString()}
          </span>{" "}
          /-
        </Typography.Title>

        {/* <Card size="small" title="TICKET SALES">
          <Table
            size="small"
            bordered
            loading={{
              spinning: isLoading || isFetching,
              indicator: loadingIndicator,
            }}
            className="invoiceBillingTable"
            dataSource={data?.data?.sales}
            columns={ticket_sales}
            rowClassName={(record) => {
              const {
                invoice_is_refund: reissue,
                invoice_is_reissued: refund,
              } = record;
              let className = "invoiceBillingTd";

              if (reissue) return (className += "reissued-row-style");
              if (refund) return (className += " refund-row-style");

              return className;
            }}
            pagination={false}
            summary={(data) => {
              data?.forEach((item) => {
                total_net_total += Fixed2(item.invoice_net_total);
                client_price += Fixed2(item.total_client_price);
              });

              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={7}>
                    <div className="report_total_footer">
                      <Typography.Text strong>Total: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={7}>
                    <div className="report_total_footer">
                      <Typography.Text strong>
                        {total_net_total.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <div className="report_total_footer">
                      <Typography.Text strong style={{ color: "#EE4266" }}>
                        {client_price.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Card> */}
      </div>
    </>
  );
};

export default ShowBspSummary;

import { DollarOutlined } from "@ant-design/icons";
import { Table, Tag, Typography } from "antd";
import React from "react";
import { HTTPResponse } from "../../../../common/types/commonTypes";
import { Fixed2 } from "../../../../common/utils/common.utils";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { ITotalDueAdvanceDetailsAll } from "../endpoints/DueAdvanceDetailsEndpoints";
import { IClickInfo, InfoType } from "../pages/DueAdvanceDetails";
import { DueAdvanceDetailsColumn } from "../utils/DueAdvanceDetailsColumn";

type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
  info: InfoType;
  setClickInfo: React.Dispatch<React.SetStateAction<IClickInfo>>;
  isLoading: boolean;
  data: HTTPResponse<ITotalDueAdvanceDetailsAll> | undefined;
  handlePaginationChange: (current: number, pageSize: number) => void;
};

const { Text } = Typography;

const ClientDueAdvance_Details_SubTable = ({
  pagination,
  info,
  setClickInfo,
  data,
  isLoading,
  handlePaginationChange,
}: Props) => {
  const LastPage = Math.ceil(
    Number(data?.count || 0) / (pagination?.pageSize || 0)
  );

  return (
    <div>
      <Table
        size="small"
        bordered
        columns={DueAdvanceDetailsColumn({
          pagination,
          type: info.data_type as "CLIENT" | "DETAILS" | "AIRLINE",
          setClickInfo,
        })}
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
        dataSource={data?.data?.results}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ["50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
        }
        summary={(_) => {
          if (pagination?.current === LastPage)
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} colSpan={6}>
                  <div className="report_total_footer">
                    <Text strong>Total: </Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={3}>
                  <div className="report_total_footer">
                    <Text strong>
                      {Fixed2(data?.data?.total.sales).toLocaleString()}
                    </Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <div className="report_total_footer">
                    <Text strong>
                      {Fixed2(data?.data?.total.pay).toLocaleString()}
                    </Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={5}>
                  <div className="report_total_footer">
                    {Fixed2(data?.data?.total.due) > 0 ? (
                      <Text style={{ color: "red" }} strong>
                        {Fixed2(data?.data?.total.due).toLocaleString()}
                      </Text>
                    ) : (
                      <Tag color="cyan" icon={<DollarOutlined />}>
                        PAID
                      </Tag>
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div className="report_total_footer">
                    <Text strong>
                      {Fixed2(
                        data?.data?.total.invoice_discount
                      ).toLocaleString()}
                    </Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div className="report_total_footer">
                    <Text
                      style={{
                        color:
                          Fixed2(data?.data?.total.profit) > 0
                            ? "green"
                            : "red",
                      }}
                      strong
                    >
                      {Fixed2(data?.data?.total.profit).toLocaleString()}
                    </Text>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
        }}
      />
    </div>
  );
};

export default ClientDueAdvance_Details_SubTable;
